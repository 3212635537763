import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, DownloadPdf, PillButton, WordCloudRenderer } from ".";
import { Symbol } from "../../pages/summary";
import { iconsMappingList } from "../../util/workshop.util";
import { BulbIcon, HeadIcon, MagnifierIcon, PersonIcon, RadarIcon } from "../../assets/icons";

const iconsMap = {
  magnifier: MagnifierIcon,
  head: HeadIcon,
  person: PersonIcon,
  radar: RadarIcon
};

const mapIconsLocationClasses = {
  0: "left-1 top-1",
  1: "right-1 top-1",
  2: "left-1 bottom-1",
  3: "right-1 bottom-1"
};

const colors = {
  task9Words: "#E59536",
  successPillarsWords: "#5DB447",
  skillsWords: "#9C65DC"
};

const InsightsHeroSection = ({
  teamName,
  mapNames,
  task9Words,
  successPillarsWords,
  skillsWords,
  isPdf = false,
  id,
  socket
}) => {
  const { t } = useTranslation();

  const heroTranslations = t("hero", { returnObjects: true });

  const {
    initialTitle,
    reflections,
    teamProfile: {
      title,
      description,
      buttons: { overall, communication, skills, pillars, maps } = {}
    },
    downloadInsights = {},
    pdf = {}
  } = heroTranslations;

  const attitudes = t("resources.attitudes", { returnObjects: true });

  const [parsedWords, setParsedWords] = useState({});
  const [wordcloudList, setWordcloudList] = useState([]);
  const [heroHighlight, setHeroHighlight] = useState("overall");

  const getColorForWord = (word) => {
    if (parsedWords.task9Words?.[word]) return colors.task9Words;
    if (parsedWords.successPillarsWords?.[word]) return colors.successPillarsWords;
    if (parsedWords.skillsWords?.[word]) return colors.skillsWords;
  };

  const customClassForWord = (word) => {
    if (parsedWords.task9Words?.[word]) return "word task9Word";
    if (parsedWords.successPillarsWords?.[word]) return "word successPillarsWord";
    if (parsedWords.skillsWords?.[word]) return "word skillsWord";
  };

  useEffect(() => {
    try {
      const allWords = document.querySelectorAll("#hero .word");
      const task9Words = document.querySelectorAll("#hero .task9Word");
      const successPillarsWords = document.querySelectorAll("#hero .successPillarsWord");
      const skillsWords = document.querySelectorAll("#hero .skillsWord");

      if (!heroHighlight || heroHighlight === "overall") {
        allWords.forEach((word) => {
          word.style.opacity = 1;
        });
      }

      if (heroHighlight === "communication") {
        allWords.forEach((word) => {
          word.style.opacity = 0.1;
          word.style.zIndex = 1;
        });
        task9Words.forEach((word) => {
          word.style.opacity = 1;
          word.style.zIndex = 20;
        });
      }
      if (heroHighlight === "skills") {
        allWords.forEach((word) => {
          word.style.opacity = 0.1;
          word.style.zIndex = 1;
        });
        skillsWords.forEach((word) => {
          word.style.opacity = 1;
          word.style.zIndex = 20;
        });
      }
      if (heroHighlight === "pillars") {
        allWords.forEach((word) => {
          word.style.opacity = 0.1;
          word.style.zIndex = 1;
        });
        successPillarsWords.forEach((word) => {
          word.style.opacity = 1;
          word.style.zIndex = 20;
        });
      }
      if (heroHighlight === "maps") {
        allWords.forEach((word) => {
          word.style.opacity = 0;
        });
      }
    } catch (error) {}
  }, [heroHighlight]);

  useEffect(() => {
    let tempObject = {};

    try {
      // Parse task 9 words first
      const { wordScores, modSelections } = task9Words;

      if (!Object.keys(wordScores)?.length) return;

      const result = Object.entries(wordScores).sort((a, b) => b[1] - a[1]);

      const selectedAttitudes = result
        .map((w) => {
          const [wordIndex, wordScore] = w;

          if (!modSelections[wordIndex]) return null;

          const word = attitudes[wordIndex].replace("<p>", "").replace("</p>", "");

          return { word, wordScore };
        })
        .filter(Boolean)
        .reduce((acc, curr) => {
          acc[curr.word] = curr.wordScore;
          return acc;
        }, {});

      tempObject.task9Words = selectedAttitudes;
    } catch (error) {
      console.error("Error parsing task 9 words", error);
    }

    try {
      // Parse skills words
      if (skillsWords && Object.keys(skillsWords)?.length > 0) tempObject.skillsWords = skillsWords;
    } catch (error) {
      console.error("Error parsing skills words", error);
    }

    try {
      // Parse success pillars words
      if (successPillarsWords && Object.keys(successPillarsWords)?.length > 0)
        tempObject.successPillarsWords = successPillarsWords;
    } catch (error) {
      console.error("Error parsing success pillars words", error);
    }

    setParsedWords({ ...tempObject });
  }, [task9Words, skillsWords, successPillarsWords]);

  useEffect(() => {
    if (Object.keys(parsedWords).length === 0) return;

    const tempList = [];

    const multiplyFactor = isPdf ? 1 : 0.6;
    const baseSize = 30 * multiplyFactor;
    const maxFontSize = 90 * multiplyFactor;
    const minFontSize = 15 * multiplyFactor;
    const scaleFactor = 15 * multiplyFactor;

    try {
      if (Object.keys(parsedWords?.task9Words)?.length > 0) {
        for (let key in parsedWords.task9Words) {
          const lowerCaseKey = key.toLowerCase();
          if (!tempList.some(([word]) => word.toLowerCase() === lowerCaseKey)) {
            tempList.push([key, maxFontSize]);
          }
        }
      }

      if (Object.keys(parsedWords?.skillsWords)?.length > 0) {
        for (let key in parsedWords.skillsWords) {
          const lowerCaseKey = key.toLowerCase();
          if (!tempList.some(([word]) => word.toLowerCase() === lowerCaseKey)) {
            let size = Math.min(maxFontSize, baseSize + parsedWords.skillsWords[key] * scaleFactor);
            size = Math.max(minFontSize, size);
            tempList.push([key, size]);
          }
        }
      }

      if (Object.keys(parsedWords?.successPillarsWords)?.length > 0) {
        for (let key in parsedWords.successPillarsWords) {
          const lowerCaseKey = key.toLowerCase();
          if (!tempList.some(([word]) => word.toLowerCase() === lowerCaseKey)) {
            let size = Math.min(
              maxFontSize,
              baseSize + parsedWords.successPillarsWords[key] * scaleFactor
            );
            size = Math.max(minFontSize, size);
            tempList.push([key, size]);
          }
        }
      }

      tempList.sort((a, b) => b[1] - a[1]);

      setWordcloudList([...tempList]);
    } catch (error) {
      console.error("Error sorting parsed words", error);
    }
  }, [parsedWords]);

  const content = () => {
    const { description, tips } = reflections?.content;
    return (
      <div id="strengths" className="block w-full">
        {!isPdf && <p className="mb-4" dangerouslySetInnerHTML={{ __html: description }} />}

        <div
          className={`rounded-lg border-2 border-gray-250/40 bg-[#FFF7EC] flex items-center gap-3 ${
            isPdf ? "px-4 py-2" : "p-4"
          }`}>
          <span className="w-12 h-12">
            <BulbIcon />
          </span>
          <ul className="list-disc pl-6">
            {tips.map((tip, index) => (
              <li
                key={index}
                className="text-[#575756]"
                dangerouslySetInnerHTML={{ __html: tip }}
              />
            ))}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div
      id="hero"
      className={
        isPdf
          ? "h-screen px-4 py-2 flex flex-col justify-start items-center overflow-hidden"
          : "mb-12"
      }>
      {!isPdf ? (
        <h2 className="text-gray-250 text-3xl font-extrabold capitalize">
          {teamName}
          <span
            className="ml-2 inline-block text-3xl font-normal font-gochi text-orange-100"
            dangerouslySetInnerHTML={{ __html: initialTitle }}
          />
        </h2>
      ) : (
        <div className="flex items-center justify-between w-full mb-4">
          <div className="flex items-center gap-2">
            <span
              data-testid="accordion-icon"
              className={`block ${isPdf ? "w-10 h-10" : "w-16 h-16"}`}
              dangerouslySetInnerHTML={{ __html: pdf?.icon }}
            />
            <div
              className="text-gray-250 text-xl font-extrabold"
              dangerouslySetInnerHTML={{ __html: pdf?.title }}
            />
          </div>
          <div className="flex items-center gap-2">
            {pdf?.wordCloudKeys?.map((wordCloudKey) => (
              <span
                key={wordCloudKey}
                className="bg-orange-200 px-6 py-1 rounded-sm min-w-24 text-center"
                dangerouslySetInnerHTML={{ __html: wordCloudKey }}
              />
            ))}
          </div>
        </div>
      )}

      {!isPdf && (
        <div className="my-12">
          <Accordion
            title={reflections?.title}
            icon={reflections?.icon}
            content={content()}
            isPdf={isPdf}
            isStatic={true}
            defaultOpen={true}
          />
        </div>
      )}
      <div
        className={
          isPdf
            ? "flex items-center justify-center bg-orange-200 px-10 py-5 rounded-2xl"
            : `grid grid-cols-1 sm:grid-cols-1 gap-4 max-w-4xl mx-auto`
        }>
        {!isPdf && (
          <div className="w-full flex flex-col items-start justify-center text-center">
            <h3
              className="text-gray-250 text-xl font-bold w-full"
              dangerouslySetInnerHTML={{ __html: title }}
            />

            <p
              className="text-gray-250 text-base font-normal mt-6"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <div className="flex flex-col items-center w-full">
              <div className="my-4">
                <PillButton
                  text={overall}
                  textColor={"white-100"}
                  borderColor={"orange-100"}
                  bgColor={"orange-100"}
                  bgHoverColor={"orange-100"}
                  textHoverColor={"orange-100"}
                  onClick={() => setHeroHighlight("overall")}
                />
              </div>
              <div className="flex flex-wrap items-center justify-center gap-4 mb-4">
                <PillButton
                  text={communication}
                  textColor={"orange-100"}
                  borderColor={"orange-100"}
                  bgColor={"transparent"}
                  bgHoverColor={"orange-100"}
                  onClick={() => setHeroHighlight("communication")}
                />
                <PillButton
                  text={skills}
                  textColor={"purple-100"}
                  borderColor={"purple-100"}
                  bgColor={"transparent"}
                  bgHoverColor={"purple-100"}
                  onClick={() => setHeroHighlight("skills")}
                />
                <PillButton
                  text={pillars}
                  textColor={"green-200"}
                  borderColor={"green-200"}
                  bgColor={"transparent"}
                  bgHoverColor={"green-200"}
                  onClick={() => setHeroHighlight("pillars")}
                />
                <PillButton
                  text={maps}
                  textColor={"orange-100"}
                  borderColor={"orange-100"}
                  bgColor={"transparent"}
                  bgHoverColor={"orange-100"}
                  onClick={() => setHeroHighlight("maps")}
                />
              </div>
            </div>
          </div>
        )}

        <div className="w-full">
          <div
            className={`rounded-2xl bg-white-100 border border-gray-750 p-2 flex flex-col mx-auto`}>
            <div className={`relative flex flex-wrap gap-0 overflow-hidden`}>
              <img
                src={require(`../../assets/images/maps/full.png`)}
                className="block w-full h-full filter brightness-75 rounded-xl"
                alt="map"
              />
              {heroHighlight === "maps" && (
                <div className="grid grid-cols-2 grid-rows-2 w-full h-full absolute left-0 top-0">
                  {Array.from({ length: 4 }, (_, index) => (
                    <div className="w-full h-full flex items-center justify-center">
                      <p
                        className="font-righteous text-lg text-white-100 text-center"
                        dangerouslySetInnerHTML={{ __html: heroTranslations?.maps?.[index] }}
                      />
                    </div>
                  ))}
                </div>
              )}
              <div
                className="absolute top-0 left-0 w-full h-full bg-white-100/20 backdrop-blur-sm z-[18]"
                style={{
                  display:
                    heroHighlight !== "maps" && heroHighlight !== "overall" ? "block" : "none"
                }}
              />
              {Array.from({ length: 4 }, (_, index) => (
                <React.Fragment key={index}>
                  <div
                    className={`absolute ${mapIconsLocationClasses[index]} flex gap-1 ${index > 1 ? "flex-col-reverse" : "flex-col"} w-14 sm:w-20 z-10`}>
                    <div className={`flex gap-1 p-1.5 bg-white-100 rounded-full`}>
                      {iconsMappingList[index].map((icon, i) => {
                        const Icon = iconsMap[icon];
                        return (
                          <span
                            key={i}
                            className="w-5 h-5 sm:w-8 sm:h-8 bg-gray-200 rounded-full p-1/2 sm:p-2">
                            <Icon />
                          </span>
                        );
                      })}
                    </div>
                    <div
                      className={`flex flex-wrap ${(index + 1) % 2 !== 0 ? "justify-start" : "justify-end"}`}>
                      {mapNames?.[index] !== undefined && (
                        <div
                          className={`flex w-4/5 sm:w-full flex-wrap sm:p-1 ${(index + 1) % 2 !== 0 ? "justify-start" : "justify-end"}`}>
                          {mapNames[index].map(({ name, symbolIndex }, symIndex) => {
                            return (
                              <div key={symIndex} className="w-full sm:w-1/2">
                                <Symbol
                                  key={symbolIndex + "-" + symIndex}
                                  name={name}
                                  symbolIndex={symbolIndex}
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ))}

              <div
                className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center w-[80%] h-[75%] z-[19]"
                style={{
                  display: heroHighlight !== "maps" ? "flex" : "none"
                }}>
                {wordcloudList.length > 0 && (
                  <WordCloudRenderer
                    id={"hero"}
                    words={wordcloudList}
                    colorFunction={getColorForWord}
                    customClassesCallback={customClassForWord}
                    weightFactorFormula={(size) => (isPdf ? size * 0.6 : size)}
                    backgroundColor={"#FAEAD7"}
                    dynamicHeightFactor={isPdf ? 1 : 0.6}
                    type={isPdf ? undefined : "html"}
                    width={isPdf ? 1300 : 1500}
                    height={isPdf ? 900 : 1500}
                    isPdf={isPdf}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {!isPdf && (
          <DownloadPdf
            btnText={t(downloadInsights?.title)}
            workshopId={id}
            socket={socket}
            className="max-w-full w-full"
          />
        )}
      </div>
    </div>
  );
};

export default InsightsHeroSection;
