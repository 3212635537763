import i18next from "i18next";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { initReactI18next } from "react-i18next";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
  useParams
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { io } from "socket.io-client";

import { WorkshopContext } from "./contexts/workshop.context";
import {
  fetchTranslations,
  getBoosterWorkshopDetails,
  getPortalDetails,
  getWorkshopInsightsData
} from "./service";

import BugReport from "./components/bugReport";
import { Loader } from "./components/loaders";
import {
  BoosterBenefits,
  BoosterLogin,
  BoosterStrengths,
  BoosterThankYou,
  BoosterUnderstanding,
  BoosterWait,
  BoosterWordCloud,
  End,
  Insights,
  Login,
  PreSummary,
  Summary,
  Task1,
  Task10,
  Task2,
  Task3,
  Task4,
  Task5,
  Task6,
  Task7,
  Task8,
  Task8b,
  Task9,
  Task9b,
  Wait
} from "./pages";

import { NewUserNotification, QRPopup } from "./components/workshop";
import VideoPage from "./pages/videoPage";
import { hexToRgba } from "./util/colors.util";
import { defaultRoles, NonPrivateRoutes } from "./util/workshop.util";

const App = () => {
  const { workshopDetails } = useContext(WorkshopContext);

  return (
    <div
      id="base-container"
      className={`font-inter ${!workshopDetails?.pdf ? "container mx-auto p-6 py-12 pb-80 md:pb-0" : ""}`}>
      <ToastContainer
        hideProgressBar={true}
        newestOnTop={true}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        limit={1}
      />
      <Routes>
        <Route path="/" element={<BaseContainer />}>
          <Route path="login" element={<Login />} />
          <Route path="wait" element={<Wait />} />
          <Route path="video/:videoId" element={<VideoPage />} />
          <Route path="task-1" element={<Task1 />} />
          <Route path="task-2" element={<Task2 />} />
          <Route path="task-3" element={<Task3 />} />
          <Route path="task-4" element={<Task4 />} />
          <Route path="task-5" element={<Task5 />} />
          <Route path="task-6" element={<Task6 />} />
          <Route path="task-7" element={<Task7 />} />
          {/* 
          Task 8 done like this now because with useParams, 
          it still picked up type from previous page in first render
           and it was cuasing issues
          */}
          <Route path="task-8/a" element={<Task8 type={"a"} />} />
          <Route path="task-8/b" element={<Task8b type={"b"} />} />
          <Route path="task-9" element={<Task9 />} />
          <Route path="task-9/b" element={<Task9b />} />
          <Route path="task-10" element={<Task10 />} />
          <Route path="pre-summary" element={<PreSummary />} />
          <Route path="summary" element={<Summary />} />
          <Route path="end" element={<End />} />
        </Route>

        <Route path="/online" element={<OnlineContainer />}>
          <Route path="" element={<Login />} />
          <Route path="wait" element={<Wait />} />
          <Route path="video/:videoId" element={<VideoPage />} />
          <Route path="task-1" element={<Task1 />} />
          <Route path="task-2" element={<Task2 />} />
          <Route path="task-3" element={<Task3 />} />
          <Route path="task-4" element={<Task4 />} />
          <Route path="task-5" element={<Task5 />} />
          <Route path="task-6" element={<Task6 />} />
          <Route path="task-7" element={<Task7 />} />
          {/* 
          Task 8 done like this now because with useParams, 
          it still picked up type from previous page in first render
           and it was cuasing issues
          */}
          <Route path="task-8/a" element={<Task8 type={"a"} />} />
          <Route path="task-8/b" element={<Task8b type={"b"} />} />
          <Route path="task-9" element={<Task9 />} />
          <Route path="task-9/b" element={<Task9b />} />
          <Route path="task-10" element={<Task10 />} />
          <Route path="pre-summary" element={<PreSummary />} />
          <Route path="summary" element={<Summary />} />
          <Route path="end" element={<End />} />
        </Route>

        <Route path="/booster" element={<BoosterContainer />}>
          <Route path="" element={<BoosterLogin />} />
          <Route path="wait" element={<BoosterWait />} />
          <Route path="understanding" element={<BoosterUnderstanding />} />
          <Route path="choose-strengths" element={<BoosterStrengths />} />
          <Route path="world-cloud" element={<BoosterWordCloud />} />
          <Route path="benefits" element={<BoosterBenefits />} />
          <Route path="thank-you" element={<BoosterThankYou />} />
        </Route>

        <Route path="/insights" element={<InsightsContainer />}>
          <Route path=":id" element={<Insights />} />
        </Route>

        {/* Not found */}
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate replace to="/not-found" />} />
      </Routes>

      {process.env.NODE_ENV === "production" && <BugReport />}
    </div>
  );
};

const BaseContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  let m = searchParams.get("m"); // Moderator token
  let p = searchParams.get("p"); // Participant token
  const pdf = searchParams.get("pdf"); // PDF token
  const pdfSection = searchParams.get("pdfSection"); // pdfSection token

  const [locked, setLocked] = useState(false);

  try {
    // Remove previous user ID as it is a new login to with workshop it
    if (m || p) Cookies.remove("userId");
    if (m) Cookies.remove("p");
    if (p) Cookies.remove("m");

    // remove cookies if not on summary page
    if (!location.pathname.includes("/summary") && (p || m)) {
      Cookies.remove("consent");
    }
  } catch (error) {
    console.log("Cookies Unavailable");
  }

  if (!m && !p) {
    try {
      m = Cookies.get("m"); // Try to get m token from cookies
      if (!m) p = Cookies.get("p"); // If m is not present, then try for p token only
    } catch (error) {
      console.log("Cookies Unavailable");
    }
  }

  const {
    socket,
    setSocket,
    setThemeConfig,
    user,
    setUser,
    setWorkshopDetails,
    setPlatform,
    setWorkshopUsers
  } = useContext(WorkshopContext);

  const [load, setLoad] = useState(false);

  const loadTranslations = async (type) => {
    const translationId = {
      mt: "physical-mission-team-workshop",
      mft: "physical-mission-future-workshop",
      mtOnline: "online-mission-team-workshop"
    };

    const { data: { en, de, fr, it } = {} } = await fetchTranslations(translationId[type]);
    const { data: { en: en_, de: de_, fr: fr_, it: it_ } = {} } =
      await fetchTranslations("workshop-resources");

    i18next.addResourceBundle("en", "translations", { ...en, resources: { ...en_ } });
    i18next.addResourceBundle("fr", "translations", { ...fr, resources: { ...fr_ } });
    i18next.addResourceBundle("de", "translations", { ...de, resources: { ...de_ } });
    i18next.addResourceBundle("it", "translations", { ...it, resources: { ...it_ } });

    i18next.changeLanguage("en"); // Initial language
  };

  useEffect(() => {
    i18next.init({
      interpolation: { escapeValue: false },
      saveMissing: false,
      returnEmptyString: false
    });
    i18next.languages = ["en", "fr", "de", "it"];
    i18next.use(initReactI18next).init({
      fallbackLng: "en",
      lng: "en",
      ns: ["translations"],
      defaultNS: "translations"
    });

    (async () => {
      try {
        const { data: { workshop, theme } = {} } = await getPortalDetails(m, p);

        await loadTranslations(workshop?.platform);
        setUser({ m, p });
        setPlatform(workshop?.platform);
        setThemeConfig({ ...theme, primary: "#E59536", primary50: hexToRgba("#E59536", 50) });
        setWorkshopDetails({ ...workshop, pdf, pdfSection });

        i18next.changeLanguage(workshop?.languageId);

        try {
          // Store in cookies that expire in 3 hours
          if (m) Cookies.set("m", m, { expires: 0.125 });
          if (p) Cookies.set("p", p, { expires: 0.125 });
        } catch (error) {
          console.log("Cookies Unavailable");
        }

        // Connect to socket
        const socketConnection = io(process.env.REACT_APP_SOCKET_URL + "/workshop", {
          extraHeaders: {
            workshopId: workshop?._id,
            platform: workshop?.platform
          }
        });

        socketConnection.on("connect", () => {
          console.log("Mobile Workshop Socket connected");
          setSocket(socketConnection);

          socketConnection.on("users", (users) => {
            setWorkshopUsers(users);
          });

          socketConnection.on("navigate", (path) => {
            navigate(path);
          });

          socketConnection.on("groupsRefreshed", (name) => {
            toast.warn(
              name +
                " has just joined workshop. Your pages will be refreshed after closing this notification.",
              {
                hideProgressBar: false,
                pauseOnHover: false,
                closeOnClick: true,
                autoClose: 2000,
                onClose: () => window.location.reload()
              }
            );
          });

          socketConnection.on("workshopEnded", (status) => {
            if (status) {
              window.location.href = `/insights/${workshop?._id}`;
              // toast.info("This works is ended");
            }
          });

          socketConnection.on("disconnect", () => {
            setSocket(null);
            setUser({ m, p });
            navigate("/login");
            toast.info(
              "You are disconnected from server. We are trying to connect you again. Please hold on.",
              {
                autoClose: true,
                hideProgressBar: false
              }
            );
          });

          socketConnection.on("error", (error) => {
            toast.error(error);
            console.log(error);
          });
        });
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data || "Portal not found");
        navigate(`/not-found`);
      }
    })();
  }, []);

  // Load the workshop once socket is connected
  useEffect(() => {
    if (socket?.connected) {
      setLoad(true);

      if (pdf) return;

      try {
        // Check if userId exists in cookies, if yes, then get user details from socket and set it in context
        const userId = Cookies.get("userId");
        if (userId) {
          socket.emit("getUserData", userId, (user) => {
            if (user) {
              setUser({ ...user, m, p });
              toast.success(user?.name + ", You are connected again.");

              socket.emit("syncToWorkshop", "", (url) => {
                const redirectUrl = url || "/wait";
                navigate(redirectUrl);
              });
            } else {
              Cookies.remove("userId");
              navigate("/login");
            }
          });
        } else {
          if (window.location.pathname !== "/login" && !p && !m) navigate("/not-found");
          else navigate("/login");
        }
      } catch (error) {
        navigate("/not-found");
        console.log(error);
        console.log("Cookies Unavailable");
      }
    }
  }, [socket]);

  useEffect(() => {
    if (socket?.connected && user?.id) {
      // Check if page is unlocked
      if (NonPrivateRoutes.includes(location.pathname)) {
        setLocked(false);
        return;
      }
      socket.emit("lockedPage", location.pathname, (unlocked) => {
        if (pdf) {
          setLocked(false);
          return;
        }

        setLocked(!unlocked);
        if (!unlocked) toast.error("Locked");
      });
    }
  }, [location.pathname, user, socket]);

  if (!load || !socket) return <Loader message="Setting up the workshop" />;

  if (locked) return <LockedPage />;
  else
    return (
      <>
        <div
          className={`${!["/summary", "/task-8/a", "/task-8/b"].includes(location.pathname) ? "max-w-[1000px] mx-auto pb-48" : ""}`}>
          <Outlet />

          {/* {(user?.role === defaultRoles.moderator ||
            user?.role === defaultRoles.moderatorAndParticipant) &&
            !NonPrivateRoutes.includes(location.pathname) && (
              <QRPopup teamName={workshopDetails?.teamName} />
            )} */}
        </div>
        {(user?.role === defaultRoles.moderator ||
          user?.role === defaultRoles.moderatorAndParticipant) && <NewUserNotification />}
      </>
    );
};

const BoosterContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  let id = searchParams.get("id"); // workshop unique id

  const [locked, setLocked] = useState(false);

  try {
    // Remove previous user ID as it is a new login to workshop
    if (id) Cookies.remove("userId");
  } catch (error) {
    console.log("Cookies Unavailable");
  }

  if (!id) {
    try {
      id = Cookies.get("bid"); // Try to get id from cookies
    } catch (error) {
      console.log("Cookies Unavailable");
    }
  }

  const { socket, setSocket, setThemeConfig, user, setUser, setWorkshopDetails, setWorkshopUsers } =
    useContext(WorkshopContext);

  const [load, setLoad] = useState(false);

  const loadTranslations = async () => {
    const { data: { en, de, fr, it } = {} } = await fetchTranslations("booster-workshop");
    const { data: { en: en_, de: de_, fr: fr_, it: it_ } = {} } =
      await fetchTranslations("workshop-resources");

    i18next.addResourceBundle("en", "translations", { ...en, resources: { ...en_ } });
    i18next.addResourceBundle("fr", "translations", { ...fr, resources: { ...fr_ } });
    i18next.addResourceBundle("de", "translations", { ...de, resources: { ...de_ } });
    i18next.addResourceBundle("it", "translations", { ...it, resources: { ...it_ } });

    i18next.changeLanguage("en"); // Initial language
  };

  useEffect(() => {
    i18next.init({
      interpolation: { escapeValue: false },
      saveMissing: false,
      returnEmptyString: false
    });
    i18next.languages = ["en", "fr", "de", "it"];
    i18next.use(initReactI18next).init({
      fallbackLng: "en",
      lng: "en",
      ns: ["translations"],
      defaultNS: "translations"
    });

    (async () => {
      try {
        const { data: { language } = {} } = await getBoosterWorkshopDetails(id);

        await loadTranslations();
        setUser({ id: null, name: "" });
        setThemeConfig({ primary: "#E59536", primary50: hexToRgba("#E59536", 50) });
        setWorkshopDetails({ id: id });
        i18next.changeLanguage(language);

        try {
          // Store in cookies that expire in 3 hours
          if (id) Cookies.set("bid", id, { expires: 0.125 });
        } catch (error) {
          console.log("Cookies Unavailable");
        }

        // Connect to socket
        const socketConnection = io(process.env.REACT_APP_SOCKET_URL + "/booster", {
          extraHeaders: {
            workshopId: id
          }
        });

        socketConnection.on("connect", () => {
          console.log("Booser Workshop Socket connected");
          setSocket(socketConnection);

          socketConnection.on("users", (users) => {
            setWorkshopUsers(users);
          });

          socketConnection.on("navigate", (path) => {
            navigate(path);
          });

          socketConnection.on("workshopEnded", (status) => {
            if (status) {
              navigate("/booster/end");
              // toast.info("This works is ended");
            }
          });

          socketConnection.on("disconnect", () => {
            setSocket(null);
            navigate("/booster");
            toast.error(
              "You are disconnected from server, please use the login link again to join the workshop."
            );
          });

          socketConnection.on("error", (error) => {
            toast.error(error);
            console.log(error);
          });
        });
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data || "Workshop not found");
        navigate(`/not-found`);
      }
    })();
  }, []);

  // Load the workshop once socket is connected
  useEffect(() => {
    if (socket?.connected) {
      setLoad(true);

      try {
        // Check if userId exists in cookies, if yes, then get user details from socket and set it in context
        const userId = Cookies.get("userId");
        if (userId) {
          socket.emit("getUserData", userId, (user) => {
            console.log("user", user, "userId", userId);
            if (user) {
              setUser({ ...user, id: userId });
              if (window.location.pathname === "/" || window.location.pathname === "/booster") {
                navigate("/booster/wait");
                return;
              }
            } else {
              Cookies.remove("userId");
              navigate("/booster");
            }
          });
        } else {
          console.log(!(window.location.pathname !== "/booster") && !id);
          if (!(window.location.pathname !== "/booster") && !id) {
            navigate("/not-found");
          } else {
            if (window.location.pathname === "/booster") navigate("/booster");
            else navigate("/booster");
          }
        }
      } catch (error) {
        navigate("/not-found");
        console.log(error);
        console.log("Cookies Unavailable");
      }
    }
  }, [socket]);

  useEffect(() => {
    if (socket?.connected && user?.id) {
      // Check if page is unlocked
      if (NonPrivateRoutes.includes(location.pathname)) {
        setLocked(false);
        return;
      }
      socket.emit("lockedPage", location.pathname, (unlocked) => {
        setLocked(!unlocked);
        if (!unlocked) toast.error("Locked");
      });
    }
  }, [location.pathname, user, socket]);

  if (!load || !socket) return <Loader message="Setting up the booster workshop" />;

  if (locked) return <LockedPage />;
  else
    return (
      <div className={"max-w-[1000px] mx-auto"}>
        <Outlet />
      </div>
    );
};

const OnlineContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  let m = searchParams.get("m"); // Moderator token
  let p = searchParams.get("p"); // Participant token
  const pdf = searchParams.get("pdf"); // PDF token
  const pdfSection = searchParams.get("pdfSection"); // pdfSection token

  const [locked, setLocked] = useState(false);

  try {
    // Remove previous user ID as it is a new login to with workshop it
    if (m || p) Cookies.remove("userId");
    if (m) Cookies.remove("p");
    if (p) Cookies.remove("m");

    // remove cookies if not on summary page
    if (!location.pathname.includes("/summary") && (p || m)) {
      Cookies.remove("consent");
    }
  } catch (error) {
    console.log("Cookies Unavailable");
  }

  if (!m && !p) {
    try {
      m = Cookies.get("m"); // Try to get m token from cookies
      if (!m) p = Cookies.get("p"); // If m is not present, then try for p token only
    } catch (error) {
      console.log("Cookies Unavailable");
    }
  }

  const {
    socket,
    setSocket,
    setThemeConfig,
    user,
    setUser,
    setWorkshopDetails,
    setPlatform,
    setWorkshopUsers
  } = useContext(WorkshopContext);

  const [load, setLoad] = useState(false);

  const loadTranslations = async (type) => {
    const translationId = {
      mt: "physical-mission-team-workshop",
      mft: "physical-mission-future-workshop",
      mtOnline: "online-mission-team-workshop"
    };

    const { data: { en, de, fr, it } = {} } = await fetchTranslations(translationId[type]);
    const { data: { en: en_, de: de_, fr: fr_, it: it_ } = {} } =
      await fetchTranslations("workshop-resources");

    i18next.addResourceBundle("en", "translations", { ...en, resources: { ...en_ } });
    i18next.addResourceBundle("fr", "translations", { ...fr, resources: { ...fr_ } });
    i18next.addResourceBundle("de", "translations", { ...de, resources: { ...de_ } });
    i18next.addResourceBundle("it", "translations", { ...it, resources: { ...it_ } });

    i18next.changeLanguage("en"); // Initial language
  };

  useEffect(() => {
    i18next.init({
      interpolation: { escapeValue: false },
      saveMissing: false,
      returnEmptyString: false
    });
    i18next.languages = ["en", "fr", "de", "it"];
    i18next.use(initReactI18next).init({
      fallbackLng: "en",
      lng: "en",
      ns: ["translations"],
      defaultNS: "translations"
    });

    (async () => {
      try {
        const { data: { workshop, theme } = {} } = await getPortalDetails(m, p);

        await loadTranslations(workshop?.platform);
        setUser({ m, p });
        setPlatform(workshop?.platform);
        setThemeConfig({ ...theme, primary: "#E59536", primary50: hexToRgba("#E59536", 50) });
        setWorkshopDetails({ ...workshop, pdf, pdfSection });

        i18next.changeLanguage(workshop?.languageId);

        try {
          // Store in cookies that expire in 3 hours
          if (m) Cookies.set("m", m, { expires: 0.125 });
          if (p) Cookies.set("p", p, { expires: 0.125 });
        } catch (error) {
          console.log("Cookies Unavailable");
        }

        // Connect to socket
        const socketConnection = io(process.env.REACT_APP_SOCKET_URL + "/workshop", {
          extraHeaders: {
            workshopId: workshop?._id,
            platform: workshop?.platform
          }
        });

        socketConnection.on("connect", () => {
          console.log("Online Workshop Socket connected");
          setSocket(socketConnection);

          socketConnection.on("users", (users) => {
            setWorkshopUsers(users);
          });

          socketConnection.on("navigate", (path) => {
            navigate(path);
          });

          socketConnection.on("groupsRefreshed", (name) => {
            toast.warn(
              name +
                " has just joined workshop. Your pages will be refreshed after closing this notification.",
              {
                hideProgressBar: false,
                pauseOnHover: false,
                closeOnClick: true,
                autoClose: 2000,
                onClose: () => window.location.reload()
              }
            );
          });

          socketConnection.on("workshopEnded", (status) => {
            if (status) window.location.href = `/insights/${workshop?._id}`;
          });

          socketConnection.on("disconnect", () => {
            setSocket(null);
            setUser({ m, p });
            navigate("/online");
            toast.error(
              "You are disconnected from server, please use the login link again to join the workshop."
            );
          });

          socketConnection.on("error", (error) => {
            toast.error(error);
            console.log(error);
          });
        });
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data || "Portal not found");
        navigate(`/not-found`);
      }
    })();
  }, []);

  // Load the workshop once socket is connected
  useEffect(() => {
    if (socket?.connected) {
      setLoad(true);

      if (pdf) return;

      try {
        // Check if userId exists in cookies, if yes, then get user details from socket and set it in context
        const userId = Cookies.get("userId");
        if (userId) {
          socket.emit("getUserData", userId, (user) => {
            if (user) {
              setUser({ ...user, m, p });
              toast.success(user?.name + ", You are connected again.");
              socket.emit("syncToWorkshop", "", (url) => {
                const redirectUrl = url || "/online/wait";
                navigate(redirectUrl);
              });
            } else {
              Cookies.remove("userId");
              navigate("/online");
            }
          });
        } else {
          if (!(window.location.pathname !== "/online") && !p && !m) navigate("/not-found");
          else navigate("/online");
        }
      } catch (error) {
        navigate("/not-found");
        console.log(error);
        console.log("Cookies Unavailable");
      }
    }
  }, [socket]);

  useEffect(() => {
    if (socket?.connected && user?.id) {
      // Check if page is unlocked
      if (NonPrivateRoutes.includes(location.pathname)) {
        setLocked(false);
        return;
      }
      socket.emit("lockedPage", location.pathname, (unlocked) => {
        if (pdf) {
          setLocked(false);
          return;
        }

        setLocked(!unlocked);
        if (!unlocked) toast.error("Locked");
      });
    }
  }, [location.pathname, user, socket]);

  if (!load || !socket) return <Loader message="Setting up the workshop" />;

  if (locked) return <LockedPage />;
  else
    return (
      <>
        <div
          className={`${
            !["/online/summary", "/online/task-8/a", "/online/task-8/b"].includes(location.pathname)
              ? "max-w-[1000px] mx-auto pb-48"
              : ""
          }`}>
          <Outlet />
        </div>
        {/* {(user?.role === defaultRoles.moderator ||
          user?.role === defaultRoles.moderatorAndParticipant) &&
          !NonPrivateRoutes.includes(location.pathname) && (
            <QRPopup teamName={workshopDetails?.teamName} />
          )} */}
        {(user?.role === defaultRoles.moderator ||
          user?.role === defaultRoles.moderatorAndParticipant) && <NewUserNotification />}
      </>
    );
};

const InsightsContainer = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const isPdfParam = searchParams.get("pdf");
  const isPdf = isPdfParam === "true";

  const { socket, setSocket, setThemeConfig, setWorkshopDetails } = useContext(WorkshopContext);

  const [load, setLoad] = useState(false);

  const loadTranslations = async () => {
    const { data: { en, de, fr, it } = {} } = await fetchTranslations("workshop-insights");
    const { data: { en: en_, de: de_, fr: fr_, it: it_ } = {} } =
      await fetchTranslations("workshop-resources");

    i18next.addResourceBundle("en", "translations", { ...en, resources: { ...en_ } });
    i18next.addResourceBundle("fr", "translations", { ...fr, resources: { ...fr_ } });
    i18next.addResourceBundle("de", "translations", { ...de, resources: { ...de_ } });
    i18next.addResourceBundle("it", "translations", { ...it, resources: { ...it_ } });

    i18next.changeLanguage("en"); // Initial language
  };

  useEffect(() => {
    i18next.init({
      interpolation: { escapeValue: false },
      saveMissing: false,
      returnEmptyString: false
    });
    i18next.languages = ["en", "fr", "de", "it"];
    i18next.use(initReactI18next).init({
      fallbackLng: "en",
      lng: "en",
      ns: ["translations"],
      defaultNS: "translations"
    });

    (async () => {
      try {
        const { data: { languageId, roomData, teamName, platform } = {} } =
          await getWorkshopInsightsData(id);

        await loadTranslations();

        // Connect to socket
        const socketConnection = io(process.env.REACT_APP_SOCKET_URL + "/workshop", {
          extraHeaders: {
            workshopId: id,
            platform: platform
          }
        });

        socketConnection.on("connect", () => {
          console.log("Insights Socket connected");
          setSocket(socketConnection);
        });

        setThemeConfig({ primary: "#E59536", primary50: hexToRgba("#E59536", 50) });
        setWorkshopDetails({
          id: id,
          roomData: roomData,
          teamName: teamName,
          pdf: isPdf,
          platform: platform
        });
        i18next.changeLanguage(languageId);
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data || "Workshop not found");
        navigate(`/not-found`);
      }
    })();
  }, []);

  useEffect(() => {
    if (socket?.connected) {
      setLoad(true);
    }
  }, [socket]);

  if (!load) return <Loader message="Setting up the Insights page" />;
  else
    return (
      <div className={"w-full mx-auto"}>
        <Outlet />
      </div>
    );
};

const NotFound = () => {
  return (
    <div className="z-50 backdrop-blur-sm fixed top-0 left-0 w-full h-full flex items-center justify-center">
      <p className="text-black text-2xl">Not found</p>
    </div>
  );
};

const LockedPage = () => {
  return (
    <div className="z-50 backdrop-blur-sm fixed top-0 left-0 w-full h-full flex items-center justify-center">
      <p className="text-black text-2xl">Locked</p>
    </div>
  );
};

export default App;
