import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { WorkshopContext } from "../contexts/workshop.context";

import {
  Button,
  CookieBanner,
  Input,
  Popup,
  Radio,
  ScreenBottom,
  Select,
  VerticalSpace
} from "../components/workshop";

import { defaultPlatforms } from "../util/workshop.util";
import { toast } from "react-toastify";

import logo from "../assets/images/logo.svg";
import lynnImage from "../assets/images/lynn.svg";

const Login = () => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({ name: "", role: "participant" });
  const [loggingIn, setLoggingIn] = useState(false);
  const [error, setError] = useState("");
  const [afterLoginPopup, setAfterLoginPopup] = useState(false);
  const [disconnectedUsers, setDisconnctedUsers] = useState([]);
  const [confirmRejoinPopup, setConfirmRejoinPopup] = useState(false);

  const {
    socket,
    user: { m, p, id } = {},
    setUser,
    workshopDetails: { platform } = {}
  } = useContext(WorkshopContext);

  const { t } = useTranslation();
  const loginTranslations = t(m ? "mtModeratorStart" : "mtParticipantStart", {
    returnObjects: true
  });

  const afterLoginPopupTranslations = t("afterJoiningPopup", {
    returnObjects: true
  });

  const rejoinTranslations = t("rejoin", { returnObjects: true });

  const loginUser = (e) => {
    e.preventDefault();

    setError("");

    if (userData.name) {
      socket.emit("login", userData);
      setLoggingIn(true);
    }
  };

  socket.on("loginError", (error) => {
    setError(error);
    setLoggingIn(false);
  });

  socket.on("loginSuccess", (savedUserData) => {
    setUser({ m, p, ...savedUserData });
    try {
      Cookies.set("userId", savedUserData?.id, { expires: 0.125 });
    } catch (error) {
      console.log("Cookies unavailable");
    }
    if (m) {
      // display popup after login
      setAfterLoginPopup(true);
    } else {
      socket.emit("syncToWorkshop", "", (url) => {
        const redirectUrl =
          url || (platform === defaultPlatforms.mtOnline ? "/online/wait" : "/wait");
        navigate(redirectUrl);
      });
    }
  });

  useEffect(() => {
    socket.emit("disconnectedUsers", (users) => {
      setDisconnctedUsers(users);
    });

    socket.on("disconnectedUsers", (users) => {
      setDisconnctedUsers(users);
    });
  }, []);

  useEffect(() => {
    if (m) {
      setUserData({ ...userData, role: "moderator" });
    }
  }, [m]);

  useEffect(() => {
    let redirectUrl;

    if (id && !loggingIn) {
      // toast.info(`You are logged in as ${name} with role: ${role}. Redirecting to wait screen.`);
      redirectUrl = platform === defaultPlatforms.mtOnline ? "/online/wait" : "/wait";
    } else {
      redirectUrl = platform === defaultPlatforms.mtOnline ? "/online" : "/login";
    }

    navigate(redirectUrl);
  }, [id]);

  return (
    <>
      <div className="flex flex-col gap-4 justify-center items-center">
        <img src={logo} alt="logo" className="w-24 mb-6" />
        <h1
          dangerouslySetInnerHTML={{ __html: loginTranslations?.heading }}
          className="text-5xl font-medium text-center font-righteous text-gray-250"
        />
        <p
          dangerouslySetInnerHTML={{ __html: loginTranslations?.subheading }}
          className="text-xl font-semibold text-gray-100 text-center"
        />
        <VerticalSpace />
        <form onSubmit={loginUser} className="w-full sm:max-w-md mx-auto flex flex-col gap-3">
          <Input
            name="participantName"
            placeholder={loginTranslations?.inputField}
            onChange={(value) => setUserData({ ...userData, name: value })}
            required={true}
            errorMessage={loginTranslations?.error}
          />
          {m && (
            <div className="block">
              <Radio
                id="moderator"
                name="participantType"
                label={loginTranslations?.radioButton?.moderator}
                onChange={(value) => setUserData({ ...userData, role: value })}
                required={true}
                defaultChecked={true}
                errorMessage={loginTranslations?.radioButton?.error}
              />
              <Radio
                id="moderatorAndParticipant"
                name="participantType"
                label={loginTranslations?.radioButton?.moderatorAndParticipant}
                onChange={(value) => setUserData({ ...userData, role: value })}
              />
            </div>
          )}

          {error && (
            <p
              dangerouslySetInnerHTML={{ __html: error }}
              className="text-red-500 text-lg text-center py-3"
            />
          )}

          <ScreenBottom extraClasses={"flex-wrap"}>
            <div className="relative mt-40">
              <img
                src={lynnImage}
                alt="lynn"
                className="w-56 h-auto block mx-auto absolute left-1/2 -top-1/4 -translate-x-1/2 -translate-y-1/2"
              />
              <button
                className="relative w-full p-6 rounded-lg bg-orange-600 border-[0.5px] border-gray-250"
                type="button"
                onClick={() => setConfirmRejoinPopup(true)}>
                <p
                  dangerouslySetInnerHTML={{ __html: rejoinTranslations?.heading }}
                  className="text-base text-gray-250 font-medium text-left"
                />
              </button>
            </div>
            <Button
              text={t("mtParticipantStart.footerbutton")}
              htmlType="submit"
              disabled={id || loggingIn}
              loading={loggingIn}
            />
          </ScreenBottom>
        </form>
      </div>

      <Popup
        open={afterLoginPopup}
        bottomPadding={false}
        hideOnActionBtnOnly={true}
        autoHeight={true}
        mobilePopupStyle={false}>
        <p
          className="font-bold text-4xl mb-4 text-center"
          dangerouslySetInnerHTML={{ __html: t("afterJoiningPopup.heading") }}
        />
        <ol className="list-none list-inside mb-10 text-2xl space-y-4">
          {afterLoginPopupTranslations?.instructions?.map((instruction, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: instruction }} />
          ))}
        </ol>
        <div className="flex justify-center mb-8">
          <img
            src={t("afterJoiningPopup.image")}
            alt="join workshop"
            className="w-full h-auto block"
          />
        </div>

        <Button
          text={t("afterJoiningPopup.actionButton")}
          onClick={() =>
            socket.emit("syncToWorkshop", "", (url) => {
              const redirectUrl =
                url || (platform === defaultPlatforms.mtOnline ? "/online/wait" : "/wait");
              navigate(redirectUrl);
            })
          }
        />
      </Popup>

      <Popup
        open={confirmRejoinPopup}
        setOpen={() => {
          if (confirmRejoinPopup === true) setConfirmRejoinPopup(false);
          else setConfirmRejoinPopup(true);
        }}
        bottomPadding={false}
        hideOnActionBtnOnly={false}
        autoHeight={true}
        mobilePopupStyle={false}>
        {confirmRejoinPopup === true ? (
          <>
            <p
              className="text-xl text-gray-100 text-center mb-4"
              dangerouslySetInnerHTML={{
                __html: rejoinTranslations?.popup?.selectNameHeading
              }}
            />

            <form
              className="flex flex-col gap-3"
              onSubmit={(e) => {
                e.preventDefault();

                const targetUserId = e.target["rejoin-select"].value;
                setConfirmRejoinPopup(targetUserId);
              }}>
              <Select
                name={"rejoin-select"}
                placeholder={rejoinTranslations?.popup?.selectNamePlaceholder}
                options={disconnectedUsers.map((du) => ({ value: du.id, label: du.name }))}
              />
              <Button text={rejoinTranslations?.popup?.confirmSelection} htmlType="submit" />
            </form>
          </>
        ) : (
          <>
            {rejoinTranslations?.popup?.heading && (
              <h2
                className="text-3xl font-bold text-center font-righteous text-gray-250"
                dangerouslySetInnerHTML={{ __html: rejoinTranslations?.popup?.heading }}
              />
            )}
            <p
              className="text-xl text-gray-100 text-center"
              dangerouslySetInnerHTML={{ __html: rejoinTranslations?.popup?.subheading }}
            />

            <p className="text-3xl font-bold text-center font-righteous text-gray-250 my-6">
              {disconnectedUsers?.find((du) => du.id === Number(confirmRejoinPopup))?.name}
            </p>

            <Button
              text={rejoinTranslations?.popup?.confirm}
              onClick={() =>
                socket.emit("rejoin", confirmRejoinPopup, (result) => {
                  console.log("rejoin result", result);

                  if (!result) {
                    toast.error("Unable to join, try normal login.");
                  }

                  setUser({ m, p, ...result });

                  console.log("setting user", result);

                  Cookies.set("userId", result?.id, { expires: 0.125 });

                  socket.emit("syncToWorkshop", "", (url) => {
                    const redirectUrl =
                      url || (platform === defaultPlatforms.mtOnline ? "/online/wait" : "/wait");
                    navigate(redirectUrl);
                  });
                })
              }
            />
          </>
        )}
      </Popup>
      <CookieBanner />
    </>
  );
};

export default Login;
