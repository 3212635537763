import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { WorkshopContext } from "../contexts/workshop.context";

import {
  Button,
  EmojiSender,
  ParticipantJoinQR,
  ParticipantsList,
  Popup,
  QRPopup,
  ScreenBottom,
  VerticalSpace
} from "../components/workshop";

import { defaultRoles } from "../util/workshop.util";

const Wait = () => {
  const { t } = useTranslation();

  const [removeUserId, setRemoveUserId] = useState(null);

  const {
    socket,
    user: { id, role } = {},
    workshopUsers,
    workshopDetails
  } = useContext(WorkshopContext);

  return (
    <div className="flex flex-col gap-4">
      {role === defaultRoles.participant && (
        <h1
          dangerouslySetInnerHTML={{ __html: t("mtParticipantWaitingRoom.heading") }}
          className="text-5xl font-bold font-righteous text-gray-250"
        />
      )}
      {role === defaultRoles.moderator && (
        <h1
          dangerouslySetInnerHTML={{ __html: t("mtModeratorwaitingRoom.heading") }}
          className="text-5xl font-bold font-righteous text-gray-250"
        />
      )}
      {role === defaultRoles.moderatorAndParticipant && (
        <h1
          dangerouslySetInnerHTML={{ __html: t("mtModeratorParticipantwaitingRoom.heading") }}
          className="text-5xl font-bold font-righteous text-gray-250"
        />
      )}
      {role === defaultRoles.participant && (
        <p
          dangerouslySetInnerHTML={{ __html: t("mtParticipantWaitingRoom.subheading") }}
          className="text-xl text-gray-100"
        />
      )}
      {role === defaultRoles.moderator && (
        <p
          dangerouslySetInnerHTML={{ __html: t("mtModeratorwaitingRoom.subheading") }}
          className="text-xl text-gray-100"
        />
      )}
      {role === defaultRoles.moderatorAndParticipant && (
        <p
          dangerouslySetInnerHTML={{ __html: t("mtModeratorParticipantwaitingRoom.subheading") }}
          className="text-xl text-gray-100"
        />
      )}

      {role !== defaultRoles.participant && (
        <>
          <VerticalSpace />
          <ParticipantJoinQR />
        </>
      )}

      <VerticalSpace />

      <div className="relative">
        <div className="absolute right-0 -top-3 z-50">
          <EmojiSender socket={socket} usersList={workshopUsers} currentUserId={id} />
        </div>
        <ParticipantsList
          heading={`${workshopUsers?.length} ${t("mtParticipantWaitingRoom.listHeading")}`}
          participants={workshopUsers}
          verticalListOnly={true}
          moderator={role !== defaultRoles.participant}
          removeUser={(id) => {
            setRemoveUserId(id);
          }}
          userOwnId={id}
        />
      </div>

      <Popup open={removeUserId} setOpen={setRemoveUserId} autoHeight={true}>
        <p
          className="text-center font-bold text-2xl text-black-100"
          dangerouslySetInnerHTML={{ __html: t("removeParticipant.heading") }}
        />

        <div className="w-full flex gap-4 mt-5">
          <span className="w-1/2">
            <Button
              text={t("removeParticipant.cancel")}
              onClick={() => setRemoveUserId(null)}
              type="secondary"
            />
          </span>
          <span className="w-1/2">
            <Button
              text={t("removeParticipant.confirm")}
              onClick={() => {
                if (removeUserId === id) return;

                socket.emit("removeUser", removeUserId);
                setRemoveUserId(null);
              }}
            />
          </span>
        </div>
      </Popup>

      <ScreenBottom extraClasses={"flex-wrap"}>
        {role !== defaultRoles.participant && (
          <div className="w-full">
            <QRPopup teamName={workshopDetails?.teamName} floating={false} />
          </div>
        )}
        {/* <Button
          text={t("mtParticipantWaitingRoom.sync")}
          type={"outline"}
          onClick={() =>
            socket.emit("syncToWorkshop", "", (url) => {
              navigate(url);
            })
          }
        /> */}
        {role !== defaultRoles.participant && (
          <Button
            text={t("mtModeratorwaitingRoom.button")}
            onClick={() => socket.emit("startWorkshop")}
          />
        )}
      </ScreenBottom>
    </div>
  );
};

export default Wait;
