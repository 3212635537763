import React, { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import WordCloud from "wordcloud";
import { getPreviousRoute, getNextRoute } from "../util/general.util";

import { WorkshopContext } from "../contexts/workshop.context";

import {
  ScreenBottom,
  Button,
  SpeakingOrder,
  MultiSelectionPicker,
  VerticalSpace,
  ParticipantsReadyList
} from "../components/workshop";

import { defaultRoles } from "../util/workshop.util";
import { capitalizeFirstLetter } from "../util/translations.util";
import { removeWhiteSpace } from "../util/canvas.util";

const colors = ["#3E3E3E", "#666666", "#E59536", "#BE6A03", "#EEB52B", "#F28907"];

const Task6 = ({ summary = false }) => {
  const [taskOrderUsers, setTaskOrderUsers] = useState([]);
  const [readyUsers, setReadyUsers] = useState([]);
  const [wordcloudDone, setWordcloudDone] = useState(false);
  const [userSelections, setUserSelections] = useState([]);

  const {
    socket,
    user: { id, role } = {},
    workshopDetails: { platform } = {}
  } = useContext(WorkshopContext);

  const { t } = useTranslation();

  const Strengths = t("resources.strengths", { returnObjects: true });

  const canvasContainerRef = useRef(null);
  const canvasRef = useRef(null);

  const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  // Event handler for 'wordcloudstop' event
  function handleWordCloudStop() {
    const canvas = canvasRef.current;
    console.log("wordcloud stopped");
    if (summary) removeWhiteSpace(canvas, canvas);
    setWordcloudDone(true);
  }

  const renderWordCloud = (strengths) => {
    try {
      let increaseBy = 1;

      const wordsList = [];

      if (taskOrderUsers.length >= 2 && taskOrderUsers.length <= 5) increaseBy = 6;

      if (taskOrderUsers.length >= 6 && taskOrderUsers.length <= 12) increaseBy = 4;

      if (taskOrderUsers.length >= 13 && taskOrderUsers.length <= 18) increaseBy = 2.5;

      Object.keys(strengths).forEach((item) => {
        wordsList.push([capitalizeFirstLetter(item), strengths[item] * increaseBy]);
      });

      const canvas = canvasRef.current;
      if (!canvas) return;

      // Make canvas height same as width
      canvas.style.height = canvas.clientWidth + "px";

      // Remove existing event listener if any
      canvas.removeEventListener("wordcloudstop", handleWordCloudStop);

      // Add the event listener for the 'wordcloudstop' event
      canvas.addEventListener("wordcloudstop", handleWordCloudStop);

      WordCloud(canvas, {
        list: wordsList,
        fontFamily: "Arial, Helvetica, sans-serif",
        fontWeight: "bold",
        gridSize: 1,
        // gridSize: Math.round((24 * canvas.clientWidth) / 1024),
        weightFactor: function (size) {
          const maxSize = 325;
          const calculatedSize = Math.pow(size, 5.5);
          return Math.min(calculatedSize, maxSize);
        },
        color: function () {
          return getRandomColor();
        },
        rotateRatio: 0.5,
        rotationSteps: 2,
        backgroundColor: "#ffffff",
        shrinkToFit: true,
        clearCanvas: true,
        drawOutOfBound: false
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    socket.emit("taskOrder", 2, (users) => {
      if (users) setTaskOrderUsers(users);
    });

    socket.on("strengths", (strengths) => {
      if (!Object.keys(strengths)?.length) return;

      renderWordCloud(strengths);

      return () => {
        // stop the renderring
        WordCloud.stop();
      };
    });

    socket.on("selectedStrengths", (selectedStrengths) => {
      setUserSelections(selectedStrengths);
    });

    socket.emit("strengths", id);
  }, [id]);

  socket.on("allUsersSelectedStrengthsReady", (readyUsers) => {
    if (readyUsers) setReadyUsers(readyUsers);
  });

  const handleBack = () => {
    const previousRoute = getPreviousRoute("/task-6", platform);
    socket.emit("navigate", previousRoute);
  };

  const handleNext = () => {
    const nextRoute = getNextRoute("/task-6", platform);
    socket.emit("navigate", nextRoute);
  };

  return (
    <div className="flex flex-col gap-4 h-full w-full">
      {!summary && (
        <>
          <h1
            dangerouslySetInnerHTML={{ __html: t("mtParticipantActivityScreen.headingFive") }}
            className="text-5xl font-medium font-righteous text-gray-250"
          />

          {role === defaultRoles.participant && (
            <p
              dangerouslySetInnerHTML={{ __html: t("mtParticipantActivityScreen.paragraphFive") }}
              className="text-xl text-gray-100"
            />
          )}
          {role === defaultRoles.moderator && (
            <p
              dangerouslySetInnerHTML={{
                __html: t("mtParticipantActivityScreen.paragraphFiveMod")
              }}
              className="text-xl text-gray-100"
            />
          )}
          {role === defaultRoles.moderatorAndParticipant && (
            <p
              dangerouslySetInnerHTML={{
                __html: t("mtParticipantActivityScreen.paragraphFiveModPart")
              }}
              className="text-xl text-gray-100"
            />
          )}

          <SpeakingOrder participants={taskOrderUsers} />

          {role !== defaultRoles.moderator && (
            <MultiSelectionPicker
              socket={socket}
              moderator={role === defaultRoles.moderator}
              userId={id}
              updateId={"updateStrengths"}
              submitAllTogether={true}
              disableSelection={userSelections?.length > 0}
              dummySelections={userSelections || []}
              title={t("mtParticipantActivityScreen.dropdownOne")}
              options={Strengths?.map((s) => s?.title)}
            />
          )}

          <VerticalSpace />
        </>
      )}

      <div ref={canvasContainerRef} className={`${!summary ? "w-full" : "w-full h-full"}`}>
        <canvas
          ref={canvasRef}
          width={1000}
          height={1000}
          className={`mx-auto w-full h-full rounded-2xl ${!summary && "border border-gray-250"}`}
        />
      </div>

      {!summary &&
        (role === defaultRoles.moderator ||
          (role === defaultRoles.moderatorAndParticipant &&
            readyUsers.includes(id?.toString()))) && (
          <ParticipantsReadyList
            readyUsers={readyUsers}
            idPrefix="task6-strengths"
            totalTime={180}
            showTimer={false}
            readyText={t("task.completed")}
            readyBGColor={"bg-green-300"}
          />
        )}

      {!summary && role !== defaultRoles.participant && (
        <ScreenBottom>
          <Button text={t("buttonBack")} onClick={handleBack} type="outline" />
          <Button text={t("buttonNext")} onClick={handleNext} />
        </ScreenBottom>
      )}

      {summary && !wordcloudDone && <span id="wordcloudDone" />}
    </div>
  );
};

export default Task6;
