import React, { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { WorkshopContext } from "../contexts/workshop.context";

import {
  ScreenBottom,
  Button,
  SpeakingOrder,
  MultiSelectionPicker,
  ImageCloudV2,
  ParticipantsReadyList
} from "../components/workshop";

import { defaultRoles } from "../util/workshop.util";
import { getPreviousRoute, getNextRoute, sortListByLocale } from "../util/general.util";
const Task7 = ({ summary = false, pdf = false }) => {
  const [taskOrderUsers, setTaskOrderUsers] = useState([]);
  const [readyUsers, setReadyUsers] = useState([]);
  const [images, setImages] = useState([]);
  const [userSelections, setUserSelections] = useState([]);

  const {
    socket,
    user: { id, role } = {},
    workshopDetails: { platform } = {}
  } = useContext(WorkshopContext);

  const { t, i18n } = useTranslation();

  const collageRef = useRef(null);

  const ProfessionalRoles = t("resources.roles", { returnObjects: true });

  const renderImageCloud = (roles) => {
    const sortedProfessionalRoles = sortListByLocale(ProfessionalRoles, i18n.language, "title");

    const imageScores = [];

    Object.keys(roles).forEach((id) => {
      const targetImage = sortedProfessionalRoles?.[id]?.image;
      imageScores.push({
        url: targetImage,
        score: roles[id]
        // url: `${imageURLBase}/${i18n.language}/${i18n.language}_${Number(id) + 1}.${imageExtension}`,
      });
    });

    setImages(imageScores);
  };

  const setCollageHeight = () => {
    try {
      const currentWidth = collageRef?.current?.clientWidth;
      collageRef.current.style.height = `${currentWidth}px`;
    } catch (error) {
      console.error("Unable to set collage height", error);
    }
  };

  useEffect(() => {
    socket.emit("taskOrder", 3, (users) => {
      if (users) setTaskOrderUsers(users);
    });

    socket.on("roles", (roles) => {
      if (!Object.keys(roles)?.length) return;

      setCollageHeight();

      renderImageCloud(roles);
    });

    socket.on("selectedRoles", (selectedRoles) => {
      setUserSelections(selectedRoles);
    });

    socket.emit("roles", id);
  }, [id]);

  useEffect(() => {
    if (!collageRef.current) return;

    socket.emit("roles", id);
  }, [id]);

  socket.on("allUsersSelectedRolesReady", (readyUsers) => {
    if (readyUsers) setReadyUsers(readyUsers);
  });

  const handleBack = () => {
    const previousRoute = getPreviousRoute("/task-7", platform);
    socket.emit("navigate", previousRoute);
  };

  const handleNext = () => {
    const nextRoute = getNextRoute("/task-7", platform);
    socket.emit("navigate", nextRoute);
  };

  return (
    <div
      className={`flex flex-col gap-4 ${summary || pdf ? "w-full h-[500px] sm:h-[900px]" : "min-h-screen"}`}>
      {!summary && (
        <>
          <h1
            dangerouslySetInnerHTML={{ __html: t("mtParticipantActivityScreen.headingSix") }}
            className="text-5xl font-medium font-righteous text-gray-250"
          />

          {role === defaultRoles.participant && (
            <p
              dangerouslySetInnerHTML={{ __html: t("mtParticipantActivityScreen.paragraphSix") }}
              className="text-xl text-gray-100"
            />
          )}
          {role === defaultRoles.moderator && (
            <p
              dangerouslySetInnerHTML={{ __html: t("mtParticipantActivityScreen.paragraphSixMod") }}
              className="text-xl text-gray-100"
            />
          )}
          {role === defaultRoles.moderatorAndParticipant && (
            <p
              dangerouslySetInnerHTML={{
                __html: t("mtParticipantActivityScreen.paragraphSixModPart")
              }}
              className="text-xl text-gray-100"
            />
          )}

          <SpeakingOrder participants={taskOrderUsers} />

          {role !== defaultRoles.moderator && (
            <MultiSelectionPicker
              socket={socket}
              moderator={role === defaultRoles.moderator}
              userId={id}
              updateId={"updateRoles"}
              submitAllTogether={true}
              disableSelection={userSelections?.length > 0}
              dummySelections={userSelections || []}
              title={t("mtParticipantActivityScreen.dropdownTwo")}
              options={ProfessionalRoles?.map((r) => r?.title)}
              maxOptions={2}
              saveWithIndex={true}
              enableSearchOption={true}
            />
          )}
        </>
      )}

      {images?.length > 0 && (
        <>
          {!pdf && !summary && (
            <p
              dangerouslySetInnerHTML={{ __html: t("imageCloudHelpText") }}
              className="text-xl text-gray-100"
            />
          )}
          <div ref={collageRef} className="w-full md:w-[900px] mx-auto h-[40dvw]">
            <ImageCloudV2 images={images} pdf={pdf} />
          </div>
        </>
      )}

      {!summary &&
        (role === defaultRoles.moderator ||
          (role === defaultRoles.moderatorAndParticipant &&
            readyUsers.includes(id?.toString()))) && (
          <ParticipantsReadyList
            readyUsers={readyUsers}
            idPrefix="task7-roles"
            totalTime={180}
            showTimer={false}
            readyText={t("task.completed")}
            readyBGColor={"bg-green-300"}
          />
        )}

      {!summary && role !== defaultRoles.participant && (
        <ScreenBottom>
          <Button text={t("buttonBack")} onClick={handleBack} type="outline" />
          <Button text={t("buttonNext")} onClick={handleNext} />
        </ScreenBottom>
      )}
    </div>
  );
};

export default Task7;
