import React, { useContext } from "react";

import { WorkshopContext } from "../contexts/workshop.context";
import {
  Header,
  Hero,
  HeroMft,
  TeamCommunicationPreferences,
  TeamRoles,
  TeamStrengths,
  Footer,
  TeamSkills,
  SuccessPillars
} from "../components/insights";

const Insights = () => {
  const { socket, workshopDetails: { id, teamName, roomData, pdf, platform } = {} } =
    useContext(WorkshopContext);

  return (
    <section
      id="insights"
      className={`font-inter mx-auto ${pdf ? "bg-white-100" : ""}`}
      style={{
        maxWidth: !pdf && "1100px"
      }}>
      {!pdf && <Header id={id} socket={socket} platform={platform} />}
      {platform === "mft" ? (
        <>
          <HeroMft
            teamName={teamName}
            mapNames={roomData?.task4}
            task9Words={roomData?.task9}
            successPillarsWords={roomData?.task10?.successPillars}
            skillsWords={roomData?.task10?.skills}
            isPdf={pdf}
            id={id}
            socket={socket}
          />
          <TeamStrengths
            strengths={roomData?.strengths}
            users={roomData?.users}
            strengthsSubmissions={roomData?.strengthsSubmissions}
            isPdf={pdf}
            platform={platform}
          />
          <TeamRoles
            roles={roomData?.roles}
            users={roomData?.users}
            rolesSubmissions={roomData?.rolesSubmissions}
            isPdf={pdf}
            platform={platform}
          />
          <TeamCommunicationPreferences
            mapNames={roomData?.task4}
            starSelections={roomData?.task8}
            users={roomData?.users}
            isPdf={pdf}
            platform={platform}
          />
          {!pdf && <Footer platform={platform} />}
        </>
      ) : (
        <>
          <Hero
            teamName={teamName}
            mapNames={roomData?.task4}
            task9Words={roomData?.task9}
            successPillarsWords={roomData?.task10?.successPillars}
            skillsWords={roomData?.task10?.skills}
            isPdf={pdf}
            id={id}
            socket={socket}
          />
          <TeamStrengths
            strengths={roomData?.strengths}
            users={roomData?.users}
            strengthsSubmissions={roomData?.strengthsSubmissions}
            isPdf={pdf}
            platform={platform}
          />
          <TeamRoles
            roles={roomData?.roles}
            users={roomData?.users}
            rolesSubmissions={roomData?.rolesSubmissions}
            isPdf={pdf}
            platform={platform}
          />
          <TeamCommunicationPreferences
            mapNames={roomData?.task4}
            starSelections={roomData?.task8}
            attitudesSelections={roomData?.task9?.modSelections}
            users={roomData?.users}
            isPdf={pdf}
            platform={platform}
          />
          <TeamSkills
            users={roomData?.users}
            successPillarsSubmissions={roomData?.task10?.successPillarsSubmissions}
            skillsSubmissions={roomData?.task10?.skillsSubmissions}
            isPdf={pdf}
          />
          <SuccessPillars
            users={roomData?.users}
            successPillarsSubmissions={roomData?.task10?.successPillarsSubmissions}
            skillsSubmissions={roomData?.task10?.skillsSubmissions}
            isPdf={pdf}
          />
          {!pdf && <Footer platform={platform} />}
        </>
      )}
    </section>
  );
};

export default Insights;
