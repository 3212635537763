import React from "react";

const pillButton = ({
  text,
  textColor,
  borderColor,
  bgColor,
  bgHoverColor,
  textHoverColor,
  onClick
}) => {
  return (
    <button
      tabIndex={1}
      className={`px-4 py-2 rounded-full text-lg font-normal border text-${textColor} border-${borderColor} bg-${bgColor} hover:text-${textHoverColor} hover:bg-${bgHoverColor}/10 active:bg-${bgHoverColor}/10 focus-visible:bg-${bgHoverColor}/10 focus:bg-${bgHoverColor}/10 focus-visible:outline-none transition-all`}
      onClick={onClick}>
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </button>
  );
};

export default pillButton;
