import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { WorkshopContext } from "../contexts/workshop.context";

import { Button, ParticipantsReadyList, ScreenBottom } from "../components/workshop";

import { defaultRoles } from "../util/workshop.util";

import { getNextRoute, getPreviousRoute } from "../util/general.util";

const Task9 = ({ summary = false }) => {
  const [wordOptions, setWordOptions] = useState([]);
  const [confirmed, setConfirmed] = useState(false);
  const [totalSelections, setTotalSelections] = useState([]);
  const [readyUsers, setReadyUsers] = useState([]);

  const {
    socket,
    user: { id, role } = {},
    workshopDetails: { platform } = {}
  } = useContext(WorkshopContext);

  const { t } = useTranslation();

  useEffect(() => {
    try {
      if (wordOptions.length !== 0) return;

      const { attitudes } = t("mtParticipantsActivityNine", { returnObjects: true });

      setWordOptions(attitudes);
    } catch (error) {
      console.log(error);
    }
  }, [wordOptions]);

  const selectWord = (index) => {
    if (totalSelections.includes(index)) {
      setTotalSelections((prev) => prev.filter((item) => item !== index));
    } else {
      if (totalSelections.length === 4) return;
      setTotalSelections((prev) => [...prev, index]);
    }
  };

  useEffect(() => {
    if (!id && !summary) return;

    socket.emit("task9ConfirmedUsers");

    socket.on("task9ConfirmedUsers", (confirmedUsers) => {
      setReadyUsers(confirmedUsers || []);
    });

    socket.emit("task9User", id);

    socket.on("task9User", (confirmed) => {
      setConfirmed(confirmed);
    });
  }, [id]);

  const handleBack = () => {
    const previousRoute = getPreviousRoute("/task-9", platform);
    socket.emit("navigate", previousRoute);
  };

  const handleNext = () => {
    const nextRoute = getNextRoute("/task-9", platform);
    socket.emit("navigate", nextRoute);
  };

  return (
    <div className="flex flex-col gap-4">
      {!summary && (
        <>
          <h1
            dangerouslySetInnerHTML={{
              __html: t("mtParticipantsActivityNine.headingA")
            }}
            className="text-5xl font-medium font-righteous text-gray-250"
          />

          {role === defaultRoles.participant && (
            <p
              dangerouslySetInnerHTML={{
                __html: !confirmed
                  ? t("mtParticipantsActivityNine.subheadingA")
                  : t("mtParticipantsActivityNine.subheadingB")
              }}
              className="text-xl text-gray-100"
            />
          )}
          {role === defaultRoles.moderator && (
            <p
              dangerouslySetInnerHTML={{
                __html: !confirmed
                  ? t("mtParticipantsActivityNine.subheadingAMod")
                  : t("mtParticipantsActivityNine.subheadingBMod")
              }}
              className="text-xl text-gray-100"
            />
          )}
          {role === defaultRoles.moderatorAndParticipant && (
            <p
              dangerouslySetInnerHTML={{
                __html: !confirmed
                  ? t("mtParticipantsActivityNine.subheadingAModPart")
                  : t("mtParticipantsActivityNine.subheadingBModPart")
              }}
              className="text-xl text-gray-100"
            />
          )}

          {!confirmed && role !== defaultRoles.moderator && (
            <div className="flex flex-wrap gap-2 items-center justify-start content-center my-4">
              {wordOptions.map((word, index) => {
                return (
                  <span
                    key={index}
                    className={`w-full md:w-fit text-center px-4 py-2 rounded-full cursor-pointer  ${
                      totalSelections.includes(index)
                        ? "bg-orange-100 text-white-100 md:hover:bg-opacity-75"
                        : "bg-white-100 border border-gray-250 text-black-100 md:hover:bg-gray-300 md:hover:text-white-100"
                    }`}
                    dangerouslySetInnerHTML={{ __html: word }}
                    onClick={() => {
                      selectWord(index);
                    }}
                  />
                );
              })}
            </div>
          )}

          {confirmed && (
            <div className="flex flex-wrap gap-2 items-center justify-start content-center my-4">
              {confirmed.map((word, index) => {
                return (
                  <span
                    key={index}
                    className={`w-full md:w-fit text-center px-4 py-2 rounded-full cursor-auto bg-orange-100 text-white-100 md:hover:bg-opacity-75`}
                    dangerouslySetInnerHTML={{ __html: wordOptions?.[word] }}
                  />
                );
              })}
            </div>
          )}
        </>
      )}

      {(role === defaultRoles.moderator ||
        (role === defaultRoles.moderatorAndParticipant && readyUsers.includes(id?.toString()))) && (
        <ParticipantsReadyList
          readyUsers={readyUsers}
          showTimer={false}
          readyText={t("task9.readyUser")}
          readyBGColor={"bg-green-300"}
        />
      )}

      {!summary && (
        <ScreenBottom>
          <div className="flex flex-col gap-2 min-w-96">
            {!confirmed &&
              (role === defaultRoles.participant ||
                role === defaultRoles.moderatorAndParticipant) && (
                <Button
                  text={t("buttonConfirm")}
                  disabled={totalSelections.length === 0}
                  onClick={() => {
                    socket.emit("task9UserSubmit", { id, totalSelections });
                  }}
                />
              )}
            {(role === defaultRoles.moderator ||
              (confirmed && role === defaultRoles.moderatorAndParticipant)) && (
              <>
                <Button text={t("buttonBack")} onClick={handleBack} type="outline" />
                <Button text={t("buttonNext")} onClick={handleNext} />
              </>
            )}
          </div>
        </ScreenBottom>
      )}
    </div>
  );
};

export default Task9;
