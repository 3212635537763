import React, { useState, useContext, useEffect } from "react";

import { WorkshopContext } from "../../contexts/workshop.context";

import { CountdownTimer, Participant } from "../workshop";

import { HourglassIcon } from "../../assets/icons";

const ParticipantsReadyList = ({
  readyUsers = [],
  idPrefix = "",
  totalTime = 30,
  onComplete = () => {},
  showTimer = true,
  readyText = "ready",
  readyBGColor = "bg-green-200"
}) => {
  const [usersList, setUsersList] = useState([]);

  const { workshopUsers } = useContext(WorkshopContext);

  useEffect(() => {
    const updatedWorkshopUsers = workshopUsers.map((user) => {
      if (readyUsers.includes(user.id?.toString()) || readyUsers.includes(user.id)) {
        return { ...user, status: "ready" };
      }
      return { ...user, status: "started" };
    });

    setUsersList(updatedWorkshopUsers);
  }, [readyUsers]);

  return (
    <div className="bg-white-100 border border-gray-250 px-4 rounded-2xl mt-5 mx-auto w-full py-8">
      {usersList.map((participant, index) => (
        <div key={index} className="my-2 flex items-center justify-between">
          <Participant name={participant.name} role={participant.role} />

          <div>
            {participant.status !== "ready" ? (
              <>
                {showTimer && (
                  <CountdownTimer
                    id={`${idPrefix}-${participant.id}`}
                    totalTime={totalTime}
                    start={true}
                    size={40}
                    strokeWidth={2}
                    onComplete={() => onComplete(participant.id)}
                  />
                )}
                {!showTimer && (
                  <span className="w-6 h-6">
                    <HourglassIcon className={"w-full h-full"} />
                  </span>
                )}
              </>
            ) : (
              <div
                className={`${readyBGColor} px-4 py-2 rounded-full`}
                dangerouslySetInnerHTML={{ __html: readyText }}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ParticipantsReadyList;
