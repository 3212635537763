import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Accordion } from "./index";
import { BulbIcon } from "../../assets/icons";
import { ImageCloudV2, Participant, Popup } from "../workshop";
import { mapSelectionsToUsers } from "../../util/insights.util";
import { sortListByLocale } from "../../util/general.util";

const TeamRoles = ({ roles, users, rolesSubmissions, isPdf, platform }) => {
  const { t, i18n } = useTranslation();

  const ProfessionalRoles = t("resources.roles", { returnObjects: true });
  const translationPrefix = platform === "mft" ? "mft.roles" : "roles";
  const teamRoles = t(translationPrefix, { returnObjects: true });
  const { chosen } = teamRoles?.popup;

  const canvasRef = useRef(null);

  const [images, setImages] = useState([]);
  const [rolesUserSelection, setRolesUserSelection] = useState({});
  const [selectedRole, setSelectedRole] = useState({
    roleIndex: "",
    roleTitle: "",
    users: [],
    image: ""
  });

  const displayRoleDetails = (url) => {
    try {
      const targetRoleObject = images?.find((img) => img.url === url);

      const { index } = targetRoleObject;

      const sortedProfessionalRoles = sortListByLocale(ProfessionalRoles, i18n.language, "title");

      setSelectedRole({
        roleIndex: index,
        roleTitle: sortedProfessionalRoles?.[index]?.title,
        users: rolesUserSelection[index],
        image:
          sortedProfessionalRoles?.[index]?.noCaption || sortedProfessionalRoles?.[index]?.image,
        description: sortedProfessionalRoles?.[index]?.description
      });
    } catch (error) {
      console.error("Error in displayRoleDetails", error);
    }
  };

  const content = () => {
    const { description, tips } = teamRoles?.content;
    return (
      <div className="block w-full">
        {!isPdf && <p className="mb-4" dangerouslySetInnerHTML={{ __html: description }} />}

        <div
          className={`rounded-lg border-2 border-gray-250/40 bg-[#FFF7EC] flex items-center gap-3 ${
            isPdf ? "px-4 py-2" : "p-4"
          }`}>
          <span className="w-12 h-12">
            <BulbIcon />
          </span>
          <ul className={`list-disc pl-6 ${isPdf ? "text-xs" : ""}`}>
            {tips.map((tip, index) => (
              <li
                key={index}
                className="text-[#575756]"
                dangerouslySetInnerHTML={{ __html: tip }}
              />
            ))}
          </ul>
        </div>

        <div className={isPdf ? "bg-orange-200 py-4 px-64 rounded-2xl mt-4" : ""}>
          <div
            ref={canvasRef}
            className="p-2 border-2 border-gray-250/40 rounded-lg overflow-hidden bg-white-100 mt-4">
            {images?.length > 0 && canvasRef?.current && (
              <ImageCloudV2 images={images} customClickCallback={displayRoleDetails} />
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderImageCloud = (roles) => {
    const imageURLBase = process.env.REACT_APP_SPATIAL_AWS_URL + "teamRoles";
    const withoutCaptionimageURLBase =
      process.env.REACT_APP_SPATIAL_AWS_URL + "teamRoles/without_caption";

    const imageExtension = "webp";

    const imageScores = [];

    Object.keys(roles).forEach((id) => {
      imageScores.push({
        index: Number(id),
        url: `${imageURLBase}/${i18n.language}/${i18n.language}_${Number(id) + 1}.${imageExtension}`,
        withoutCaptionUrl: `${withoutCaptionimageURLBase}/${Number(id) + 1}.${imageExtension}`,
        score: roles[id]
      });
    });

    setImages(imageScores);
  };

  useEffect(() => {
    if (!roles || roles?.length === 0 || !canvasRef?.current) return;

    try {
      const canvasWidth = canvasRef.current.clientWidth;
      canvasRef.current.style.height = isPdf ? "60vh" : `${canvasWidth}px`;

      renderImageCloud(roles);
      setRolesUserSelection(mapSelectionsToUsers(users, rolesSubmissions));
    } catch (error) {
      console.log("Unable to render image cloud");
    }
  }, [roles, users, rolesSubmissions, canvasRef]);

  return (
    <div
      id="roles"
      className={
        isPdf ? "h-screen px-4 flex flex-col justify-start items-center overflow-hidden " : "mb-12"
      }>
      <Accordion
        title={teamRoles?.title}
        icon={teamRoles?.icon}
        content={content()}
        isPdf={isPdf}
      />

      <Popup
        open={selectedRole?.roleTitle && selectedRole?.users}
        setOpen={setSelectedRole}
        autoHeight={true}
        maxWidth="sm:max-w-3xl"
        noSpacePopup={true}
        mobilePopupStyle={false}>
        <div className="w-full flex flex-col sm:flex-row gap-3">
          <div
            className="w-full sm:w-[30%] h-60 sm:h-auto bg-cover bg-center"
            style={{
              backgroundImage: `url(${selectedRole?.image})`,
              backgroundRepeat: "no-repeat"
            }}
          />
          <div className="w-full sm:w-[70%] pl-2 pr-4 py-4">
            <div className="flex gap-2 items-center justify-start flex-wrap w-full mb-6">
              <span
                className="text-[#454444] text-2xl font-bold uppercase"
                dangerouslySetInnerHTML={{ __html: chosen }}
              />
              <div className="flex gap-2 flex-wrap">
                {selectedRole?.users?.map((user, index) => (
                  <Participant key={index} name={user} showFullName={false} />
                ))}
              </div>
            </div>
            <h2 className="text-3xl mb-4 font-gochi text-orange-100">{selectedRole?.roleTitle}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedRole?.description
              }}
            />
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default TeamRoles;
