import React from "react";
import { useTranslation } from "react-i18next";

import { BulbIcon } from "../../assets/icons";
import { Accordion } from "./index";
import { getInitials } from "../../util/general.util";

const SuccessPillars = ({ successPillarsSubmissions = {}, users = {}, isPdf = false }) => {
  const { t } = useTranslation();

  const teamSuccessPillarsAndSkills = t("successPillars", { returnObjects: true });
  const successPillars = t("resources.successPillars", { returnObjects: true });

  const getPillarUsers = (pillar) => {
    return Object.entries(successPillarsSubmissions)
      .filter(([userId, value]) => value === pillar)
      .map(([userId]) => {
        const user = users.find((u) => u.id.toString() === userId);
        return {
          id: userId,
          name: user ? user.name : userId
        };
      });
  };

  const content = () => {
    const { description, tips } = teamSuccessPillarsAndSkills?.content;
    return (
      <div id="successPillars" className="w-full">
        {!isPdf && <p className="mb-4" dangerouslySetInnerHTML={{ __html: description }} />}

        <div
          className={`rounded-lg border-2 border-gray-250/40 bg-[#FFF7EC] flex items-center gap-3 ${
            isPdf ? "px-4 py-2" : "p-4"
          }`}>
          <span className="w-12 h-12">
            <BulbIcon />
          </span>
          <ul className={`list-disc pl-6 ${isPdf ? "text-xs" : ""}`}>
            {tips.map((tip, index) => (
              <li
                key={index}
                className="text-[#575756]"
                dangerouslySetInnerHTML={{ __html: tip }}
              />
            ))}
          </ul>
        </div>

        <div className={`w-full mt-4 ${isPdf ? "bg-orange-200 py-4 px-2 rounded-2xl" : ""}`}>
          <div
            className={`w-full md:max-w-4xl mx-auto ${
              isPdf ? "grid grid-cols-2 gap-4" : "flex flex-col my-12"
            }`}>
            {successPillars.map((pillar, index) => {
              const pillarUsers = getPillarUsers(pillar);
              if (pillarUsers.length === 0) return null;

              return (
                <div key={index} className={`${!isPdf && "mb-6"} last:mb-0`}>
                  <div className="flex items-center gap-2 mb-2">
                    <span className="text-lg">🏆</span>
                    <p
                      className="font-medium text-green-200"
                      dangerouslySetInnerHTML={{ __html: pillar }}
                    />
                  </div>
                  <div className="bg-white-100 rounded-lg p-2 border border-gray-250/40">
                    <div className="flex flex-wrap gap-4">
                      {pillarUsers.map((user) => (
                        <UserSkillTag key={user.id} user={user} />
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      id="successPillars"
      className={
        isPdf ? "h-screen px-4 flex flex-col justify-start items-center overflow-hidden" : "mb-12"
      }>
      <Accordion
        title={teamSuccessPillarsAndSkills?.title}
        icon={teamSuccessPillarsAndSkills?.icon}
        content={content()}
        isPdf={isPdf}
      />
    </div>
  );
};

const UserSkillTag = ({ user }) => {
  const initials = getInitials(user.name);
  return (
    <div className="w-8 h-8 bg-orange-100 rounded-full flex items-center justify-center text-xs font-bold text-white-100 flex-shrink-0">
      {initials}
    </div>
  );
};

export default SuccessPillars;
