import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Popup from "./popup";
import Input from "./input";

import { sortListByLocale } from "../../util/general.util";

import { DownArrowIcon, CheckedIcon } from "../../assets/icons";

const MultiSelectionPicker = ({
  socket,
  moderator,
  userId,
  updateId,
  submitAllTogether = false,
  disableSelection = false,
  title,
  options = [],
  maxOptions = 3,
  saveWithIndex = false,
  alphabeticalOrder = true,
  enableSearchOption = false,
  dummySelections = [], // This is only to show dummy selections
  updateLocalSelection, // This passes selections to parent for dummy selections list,
  onChange
}) => {
  const { i18n, t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [alphabetizedList, setAlphabetizedList] = useState([]);
  const [disableInput, setDisableInput] = useState(disableSelection);

  const sortedOptions = useMemo(() => {
    if (alphabeticalOrder) {
      return sortListByLocale([...options], i18n.language);
    }
    return options;
  }, [options, alphabeticalOrder, i18n.language]);

  const selectOption = (option) => {
    if (moderator || disableSelection) return;

    const exists = selected.find((s) => s.index === option.index);

    if (maxOptions === 1 && !exists) {
      /** Act as a radio button */

      if (selected.length) {
        const [currentSelection] = selected;
        socket.emit(updateId, {
          title: saveWithIndex ? currentSelection.index : currentSelection.title,
          score: -1
        });
      }

      setSelected([option]);

      socket.emit(updateId, {
        userId: userId,
        title: saveWithIndex ? option.index : option.title,
        score: 1
      });

      if (!submitAllTogether) {
        setOpen(false);
        if (onChange) onChange();
      }
      return;
    }

    if (selected.length === maxOptions && !exists) {
      if (!submitAllTogether) setOpen(false);
      return;
    }

    let newSelected;
    if (exists) {
      newSelected = selected.filter((s) => s.index !== option.index);
    } else {
      newSelected = [...selected, option];
    }

    setSelected(newSelected);

    if (!submitAllTogether) {
      socket.emit(updateId, {
        title: saveWithIndex ? option.index : option.title,
        score: exists ? -1 : 1
      });
      if (onChange) onChange();
    }
  };

  const submitAllOptions = () => {
    if (selected?.length === maxOptions) {
      socket.emit(updateId, {
        userId: userId,
        titles: selected.map((s) => (saveWithIndex ? s.index : s.title)),
        score: 1
      });
      setOpen(false);
      setDisableInput(true);
      if (onChange) onChange();
    } else {
      toast.info(t("error.selectAllOptions"));
    }
  };

  useEffect(() => {
    if (searchQuery === "") {
      setAlphabetizedList(sortedOptions);
    } else {
      const filteredOptions = sortedOptions.filter((option) =>
        option.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setAlphabetizedList(filteredOptions);
    }
  }, [searchQuery, sortedOptions]);

  useEffect(() => {
    if (updateLocalSelection) {
      updateLocalSelection(selected);
    }
  }, [selected]);

  useEffect(() => {
    setDisableInput(disableSelection);
  }, [disableSelection]);

  return (
    <>
      <div
        className="mb-6 rounded-lg p-4 bg-white-100 border-[0.5px] border-gray-250 flex flex-row gap-3 items-center justify-between cursor-pointer"
        onClick={() => {
          if (disableInput) return;

          setOpen(!open);
        }}>
        {selected?.length < maxOptions && !dummySelections?.length && (
          <>
            <span
              className="text-md font-normal text-gray-300"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <span className="w-4 h-4">
              <DownArrowIcon />
            </span>
          </>
        )}

        {(selected?.length === maxOptions || dummySelections?.length > 0) && (
          <div className="div">
            <span
              className="block text-sm font-normal text-gray-100 mb-2"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div className="flex flex-row w-full gap-3 flex-wrap">
              {alphabetizedList?.length > 0 &&
                dummySelections.map((option, index) => {
                  return (
                    <span
                      key={index}
                      className="text-lg font-normal text-gray-300"
                      dangerouslySetInnerHTML={{
                        __html: `${!saveWithIndex ? option?.title || option : alphabetizedList?.[option]?.title || alphabetizedList?.[option]}${index + 1 < maxOptions ? "," : ""}`
                      }}
                    />
                  );
                })}
              {!dummySelections?.length > 0 &&
                selected.map((option, index) => (
                  <span
                    key={index}
                    className="text-lg font-normal text-gray-300"
                    dangerouslySetInnerHTML={{
                      __html: `${option?.title}${index + 1 < maxOptions ? "," : ""}`
                    }}
                  />
                ))}
            </div>
          </div>
        )}
      </div>

      <Popup
        open={open}
        setOpen={setOpen}
        headerButton={{
          btnText: t("popupSubmit"),
          callback: submitAllTogether ? submitAllOptions : null
        }}>
        {enableSearchOption && (
          <div className="px-4">
            <Input
              onChange={setSearchQuery}
              placeholder={t("searchFieldPlaceholder")}
              className="!bg-white-100 border !border-solid border-gray-200"
            />
          </div>
        )}
        <div className="flex flex-col gap-4 p-4">
          {alphabetizedList.map((option, index) => (
            <Option
              key={index}
              index={index}
              title={option}
              selected={selected.find((option) => option.index === index)}
              onClick={selectOption}
            />
          ))}
        </div>
      </Popup>
    </>
  );
};

const Option = ({ index, title, selected, onClick }) => {
  return (
    <div
      className={`grid grid-cols-[1fr_30px] cursor-pointer`}
      onClick={() => onClick({ index, title })}>
      <span
        className="text-lg font-normal text-gray-300"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <span className="w-6 h-6 justify-self-end">
        <CheckedIcon bg={selected ? "#E59536" : "none"} />
      </span>
    </div>
  );
};

export default MultiSelectionPicker;
