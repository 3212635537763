import React from "react";

import { defaultRoles } from "../../util/workshop.util";

import { CrossIcon } from "../../assets/icons";

const Participant = ({ id, name, role, moderator, removeUser, showFullName = true }) => {
  return (
    <div className="flex justify-between items-center">
      <div className="flex gap-2 items-center justify-start">
        <div
          className={`${showFullName ? "w-12 h-12 p-2" : "w-10 h-10 p-1"} rounded-full ${role !== defaultRoles.participant ? "bg-orange-100 text-white-100" : "bg-white-100 border-orange-100 border-2 text-black-100"} font-bold flex items-center justify-center`}>
          <span className="text-base font-medium uppercase">
            {name?.charAt(0)}
            {name?.charAt(1)}
          </span>
        </div>
        {showFullName && <p className="text-xl font-medium text-gray-250">{name}</p>}
      </div>

      {id && moderator && removeUser && (
        <button className="w-8 h-8 p-1 cursor-pointer text-gray-250" onClick={() => removeUser(id)}>
          <CrossIcon />
        </button>
      )}
    </div>
  );
};

export default Participant;
