import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Popup from "./popup";

import { AddIconWhite, HeadIcon, MagnifierIcon, PersonIcon, RadarIcon } from "../../assets/icons";
import { iconsMappingList } from "../../util/workshop.util";
import { getInitials } from "../../util/general.util";

const iconsMap = {
  magnifier: MagnifierIcon,
  head: HeadIcon,
  person: PersonIcon,
  radar: RadarIcon
};

const MapRoundedByIndex = {
  0: "rounded-tl-2xl border-t-2 border-l-2",
  1: "rounded-tr-2xl border-t-2 border-r-2",
  2: "rounded-bl-2xl border-l-2 border-b-2",
  3: "rounded-br-2xl  border-b-2 border-r-2"
};

const MapPicker = ({
  socket,
  id,
  updateId,
  name,
  userId,
  moderator,
  summary = false,
  pdf = false,
  section
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [mapNames, setMapNames] = useState({});

  useEffect(() => {
    socket.on(id, (data) => {
      try {
        if (data) {
          setMapNames(data);
        }
      } catch (error) {
        console.log(error);
      }
    });

    socket.emit(id);
  }, [id]);

  return (
    <>
      <div
        className={`grid ${section === undefined ? "grid-cols-2" : "grid-cols-1"} gap-0 ${pdf ? "w-full h-full mx-auto grid-rows-2" : ""}`}>
        {[0, 1, 2, 3].map(({}, index) => {
          if (section !== undefined && section !== index) return null;
          return (
            <div
              key={index}
              className={`relative cursor-pointer`}
              onClick={() => {
                if (moderator || summary) return;
                setOpen(index);
              }}>
              <img
                src={require(`../../assets/images/maps/${index}.png`)}
                className={`block w-full h-auto ${!summary && MapRoundedByIndex[index]} border-gray-250 ${pdf ? "w-full h-full object-cover" : ""}`}
              />
              <div className="absolute top-0 left-0 w-full h-full flex flex-wrap gap-1 items-center justify-center content-center p-4">
                {mapNames[index] !== undefined &&
                  mapNames[index].map(({ id, name, symbolIndex }, symIndex) => {
                    return (
                      <Symbol
                        key={symbolIndex + "-" + symIndex}
                        name={name}
                        symbolIndex={symbolIndex}
                        onClick={(e) => {
                          e.stopPropagation();

                          if (summary) return;

                          if (id !== userId) return;

                          socket.emit(updateId, {
                            userId,
                            name,
                            mapIndex: index,
                            symbolIndex: symbolIndex
                          });
                        }}
                      />
                    );
                  })}
                {!summary && (
                  <span
                    className="w-12 h-12 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none bg-white rounded-full bg-orange-100"
                    style={{ opacity: mapNames?.[index]?.length > 0 ? 0 : 1 }}>
                    <AddIconWhite />
                  </span>
                )}
                <div className="absolute right-0 bottom-0 flex gap-2 p-2">
                  {iconsMappingList[index].map((icon, i) => {
                    const Icon = iconsMap[icon];
                    return (
                      <span key={i} className="w-12 h-12 bg-gray-200 rounded-full p-2">
                        <Icon />
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {!summary && (
        <Popup open={open !== false} setOpen={setOpen} autoHeight>
          <div className="flex flex-col gap-4">
            <p
              className="text-lg text-black-100"
              dangerouslySetInnerHTML={{ __html: t("mtActivityFourOptions.both") }}
            />
            <div
              className="flex flex-row gap-2 items-center justify-start cursor-pointer"
              onClick={() => {
                socket.emit(updateId, { userId, name, mapIndex: open, symbolIndex: 0 });
                setOpen(false);
              }}>
              <span
                className={`w-12 h-12 p-2 rounded-full bg-orange-100 text-white-100 font-bold flex items-center justify-center`}>
                <span className="text-base uppercase">
                  {name?.charAt(0)}
                  {name?.charAt(1)}
                </span>
              </span>
              <p dangerouslySetInnerHTML={{ __html: t("mtActivityFourOptions.one") }} />
            </div>
            <div
              className="flex flex-row gap-2 items-center justify-start cursor-pointer"
              onClick={() => {
                socket.emit(updateId, { userId, name, mapIndex: open, symbolIndex: 1 });
                setOpen(false);
              }}>
              <span
                className={`w-12 h-12 p-2 rounded-full bg-white-100 text-black-100 border-orange-100 border-2 font-bold flex items-center justify-center`}>
                <span className="text-base uppercase">
                  {name?.charAt(0)}
                  {name?.charAt(1)}
                </span>
              </span>
              <p dangerouslySetInnerHTML={{ __html: t("mtActivityFourOptions.two") }} />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

const Symbol = ({ name, symbolIndex, onClick }) => {
  return (
    <span
      className={`relative z-10 w-12 h-12 p-2 rounded-full ${symbolIndex === 0 ? "bg-orange-100 text-white-100" : "bg-white-100 text-black-100 border-orange-100 border-2"} font-bold flex items-center justify-center`}
      onClick={onClick}>
      <span className="text-sm uppercase">{getInitials(name)}</span>
    </span>
  );
};

export default MapPicker;
