import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion } from "./index";
import { getInitials } from "../../util/general.util";
import { BulbIcon } from "../../assets/icons";

const TeamSkills = ({ skillsSubmissions = {}, users = [], isPdf = false }) => {
  const { t } = useTranslation();
  const teamSkills = t("skills", { returnObjects: true });

  const content = () => {
    const { description, tips } = teamSkills?.content;

    return (
      <div className="w-full">
        {!isPdf && <p className="mb-4" dangerouslySetInnerHTML={{ __html: description }} />}

        <div
          className={`rounded-lg border-2 border-gray-250/40 bg-[#FFF7EC] flex items-center gap-3 ${
            isPdf ? "px-4 py-2" : "p-4"
          }`}>
          <span className="w-12 h-12">
            <BulbIcon />
          </span>
          <ul className={`list-disc pl-6 ${isPdf ? "text-xs" : ""}`}>
            {tips.map((tip, index) => (
              <li
                key={index}
                className="text-[#575756]"
                dangerouslySetInnerHTML={{ __html: tip }}
              />
            ))}
          </ul>
        </div>

        <div className={`w-full mt-4 ${isPdf ? "bg-orange-200 py-4 px-2 rounded-2xl" : ""}`}>
          <div
            className={`md:max-w-4xl mx-auto ${
              isPdf ? " grid grid-cols-2 gap-4" : "flex flex-col items-center my-12"
            }`}>
            {Object.entries(skillsSubmissions).map(([userId, skills]) => {
              const user = users.find((u) => u.id.toString() === userId);
              return (
                <UserSkillTag
                  key={userId}
                  user={{
                    id: userId,
                    name: user?.name || userId,
                    skills: skills
                  }}
                  isPdf={isPdf}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      id="skills"
      className={
        isPdf ? "h-screen px-4 flex flex-col justify-start items-center overflow-hidden" : "mb-12"
      }>
      <Accordion
        title={teamSkills?.title}
        icon={teamSkills?.icon}
        content={content()}
        isPdf={isPdf}
      />
    </div>
  );
};

const UserSkillTag = ({ user, isPdf }) => {
  const initials = getInitials(user.name);

  return (
    <div
      className={`flex items-center space-x-4 ${!isPdf && "mb-4"} last:mb-0 w-full bg-white-100 rounded-lg p-2 border border-gray-250/40`}>
      <div className="w-8 h-8 bg-orange-100 rounded-full flex items-center justify-center text-xs font-bold text-white-100 flex-shrink-0">
        {initials}
      </div>
      <div className="flex-1">
        <div className={`font-semibold text-purple-200 ${isPdf ? "text-sm" : "text-md"}`}>
          {user.skills.map((skill, index) => (
            <p key={index}>{skill}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamSkills;
