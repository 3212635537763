import React, { useLayoutEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";

import { WorkshopContext } from "../../contexts/workshop.context";
import { defaultRoles, NonPrivateRoutes } from "../../util/workshop.util";
import QRPopup from "./qrPopup";

const ScreenBottom = ({ extraClasses, children }) => {
  const [hasExceeded, setHasExceeded] = useState(false);
  const location = useLocation();

  const { user: { role } = {}, workshopDetails: { teamName } = {} } = useContext(WorkshopContext);

  useLayoutEffect(() => {
    const element = document.getElementById("screen-bottom");
    const parentElement = document.getElementById("base-container");

    const getParentHeight = () => {
      return parentElement.offsetHeight;
    };

    const getElementHeight = () => {
      return element.offsetHeight;
    };

    const calculateHeight = () => {
      const parentHeight = getParentHeight();
      const elementHeight = getElementHeight();
      const totalHeight = parentHeight + elementHeight;

      if (totalHeight > window.innerHeight) {
        setHasExceeded(true);
      } else {
        setHasExceeded(false);
      }
    };

    window.addEventListener("resize", calculateHeight);
    window.addEventListener("orientationchange", calculateHeight);

    calculateHeight();

    return () => {
      window.removeEventListener("resize", calculateHeight);
      window.removeEventListener("orientationchange", calculateHeight);
    };
  }, []);

  return (
    <div
      id="screen-bottom"
      className={`${hasExceeded || ["/summary", "/task-4"].includes(location.pathname) ? "static mt-10" : "mt-10"} bottom-0 left-0 right-0 w-full p-4 pb-12 bg-orange-50 z-10`}>
      {(role === defaultRoles.moderator || role === defaultRoles.moderatorAndParticipant) &&
        !NonPrivateRoutes.includes(location.pathname) && (
          <QRPopup teamName={teamName} floating={false} />
        )}
      <div
        className={`w-full sm:max-w-md mx-auto flex items-center justify-center gap-4 ${extraClasses}`}>
        {children}
      </div>
    </div>
  );
};

export default ScreenBottom;
