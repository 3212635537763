import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { WorkshopContext } from "../contexts/workshop.context";

import {
  ScreenBottom,
  Button,
  ParticipantsList,
  CountdownTimer,
  SymbolPicker,
  UserGroup
} from "../components/workshop";

import { defaultPlatforms, defaultRoles } from "../util/workshop.util";
import { getNextRoute, getPreviousRoute } from "../util/general.util";

const TASK_ID = 1;

const Task3 = () => {
  const [allGroups, setAllGroups] = useState([]);
  const [groupTaskStatus, setGroupTaskStatus] = useState({});
  const [groupId, setGroupId] = useState(undefined);
  const [userGroup, setUserGroup] = useState([]);
  const [startTask, setStartTask] = useState(false);
  const [finishedTask, setFinishedTask] = useState(false);

  const {
    socket,
    user: { id, role } = {},
    workshopDetails: { platform } = {}
  } = useContext(WorkshopContext);

  const { t } = useTranslation();

  useEffect(() => {
    if (groupId === undefined)
      socket.emit(
        "userGroupForTask",
        { taskId: TASK_ID, userId: id },
        ({ groupIndex, userGroup }) => {
          if (groupIndex !== undefined) setGroupId(groupIndex);
          if (userGroup) setUserGroup(userGroup);
        }
      );

    socket.emit("allGroupsOfTask", TASK_ID, (groups) => {
      if (groups) setAllGroups(groups);
    });

    socket.emit("groupTaskStatus", TASK_ID, (status) => {
      if (status) setGroupTaskStatus(status);

      if (groupId === undefined || groupId < 0) return;

      if (status?.[groupId]?.started) setStartTask(true);

      if (status?.[groupId]?.finished) {
        setFinishedTask(true);
      }
    });

    socket.on("groupTaskStatus", (status) => {
      setGroupTaskStatus(status?.[TASK_ID]);

      if (groupId === undefined || groupId < 0) return;

      if (status?.[TASK_ID]?.[groupId]?.started) setStartTask(true);

      if (status?.[TASK_ID]?.[groupId]?.finished) {
        setFinishedTask(true);
      }
    });
  }, [id, role, groupId]);

  const handleBack = () => {
    const previousRoute = getPreviousRoute("/task-3", platform);
    socket.emit("navigate", previousRoute);
  };

  const handleNext = () => {
    const nextRoute = getNextRoute("/task-3", platform);
    socket.emit("navigate", nextRoute);
  };

  return (
    <div className="flex flex-col gap-4">
      {role !== defaultRoles.moderator && (
        <h1
          dangerouslySetInnerHTML={{ __html: t("mtParticipantActivityScreen2.headingOne") }}
          className="text-5xl font-medium font-righteous text-gray-250"
        />
      )}
      {role === defaultRoles.participant && !startTask && (
        <p
          dangerouslySetInnerHTML={{ __html: t("mtParticipantPreActivity2.subheading") }}
          className="text-xl text-gray-100"
        />
      )}
      {role === defaultRoles.moderator && !startTask && (
        <p
          dangerouslySetInnerHTML={{ __html: t("mtParticipantPreActivity2.subheadingMod") }}
          className="text-xl text-gray-100"
        />
      )}
      {role === defaultRoles.moderatorAndParticipant && !startTask && (
        <p
          dangerouslySetInnerHTML={{ __html: t("mtParticipantPreActivity2.subheadingModPart") }}
          className="text-xl text-gray-100"
        />
      )}

      {role !== defaultRoles.moderator && startTask && (
        <>
          <p
            dangerouslySetInnerHTML={{ __html: t("mtParticipantActivityScreen.paragraphTwo") }}
            className="text-xl text-gray-100"
          />

          <UserGroup participants={userGroup} />

          {(role === defaultRoles.participant ||
            (role === defaultRoles.moderatorAndParticipant && !finishedTask)) && (
            <SymbolPicker
              socket={socket}
              id={"task3"}
              updateId={"updateTask3"}
              groupId={groupId}
              popupHeading={t("mtActivityThreePopupHeading", { returnObjects: true })}
              chosenOptionHeadings={t("mtActivityThree", { returnObjects: true })}
              optionsHeadings={t("activityThreeOptions", { returnObjects: true })}
              optionsImages={[...Array(4).keys()].map((index) =>
                require(`../assets/images/task3/${index}.png`)
              )}
              chosenOptionsBottomIcons={[
                ["magnifier", "head"],
                ["magnifier", "person"],
                ["radar", "head"],
                ["radar", "person"]
              ]}
              finishedTask={finishedTask}
            />
          )}
        </>
      )}

      {role === defaultRoles.moderator && (
        <>
          <h1
            dangerouslySetInnerHTML={{
              __html:
                platform === defaultPlatforms.mtOnline
                  ? t("mtParticipantActivityScreen.headingTwo")
                  : t("listOfGroups.heading")
            }}
            className="text-2xl font-bold font-righteous text-gray-250"
          />
          <p
            dangerouslySetInnerHTML={{ __html: t("listOfGroups.subHeading") }}
            className="text-xl text-gray-100"
          />
        </>
      )}

      {role !== defaultRoles.moderator && !startTask && (
        <ParticipantsList
          heading={
            platform === defaultPlatforms.mtOnline ? "" : t("mtParticipantPreActivity.listHeading")
          }
          participants={userGroup}
        />
      )}

      {(role === defaultRoles.moderator ||
        (role === defaultRoles.moderatorAndParticipant && finishedTask)) && (
        <div className="flex flex-col gap-5">
          {allGroups.map((group, index) => {
            return (
              <div
                key={index}
                className={`relative flex flex-row justify-between gap-4 bg-white-100 p-4 rounded-lg border border-black-100 ${platform === defaultPlatforms.mtOnline ? "items-start" : "items-center"}`}>
                <ParticipantsList
                  key={index}
                  heading={
                    platform === defaultPlatforms.mtOnline
                      ? t("teamTaskParticipants.heading")
                      : t("listOfGroups.group") + " " + (index + 1)
                  }
                  completedText={t("task.completed")}
                  participants={group}
                  started={groupTaskStatus?.[index]?.started}
                  finished={groupTaskStatus?.[index]?.finished}
                  showHourGlass={platform !== defaultPlatforms.mtOnline}
                />
                {platform === defaultPlatforms.mtOnline &&
                groupTaskStatus?.[index]?.finished ? null : (
                  <CountdownTimer
                    id={`${TASK_ID}-${index}`}
                    start={groupTaskStatus?.[index]?.started}
                    finished={groupTaskStatus?.[index]?.finished}
                    greyedOnCompletion
                    totalTime={210}
                    customClass={`absolute right-0 ${group?.length === 2 ? "top-[55%]" : "top-1/2"} -translate-y-1/2 -translate-x-1/4`}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}

      <ScreenBottom>
        {role === defaultRoles.moderator && (
          <>
            <Button text={t("buttonBack")} onClick={handleBack} type="outline" />
            <Button text={t("buttonNext")} onClick={handleNext} />
          </>
        )}
        {role !== defaultRoles.moderator && (
          <div className="w-full flex flex-col gap-2">
            <div className="bg-white-100 rounded-2xl border border-gray-250 p-4 flex flex-row gap-3 items-center justify-start w-full">
              <CountdownTimer
                id={`${TASK_ID}-${groupId}`}
                start={startTask}
                finished={finishedTask}
                totalTime={210}
                onComplete={() =>
                  socket.emit("updateGroupTaskStatus", {
                    taskId: TASK_ID,
                    groupId,
                    started: true,
                    finished: true
                  })
                }
              />

              {!startTask && !finishedTask && (
                <div className="block">
                  <p
                    className="text-base font-bold font-righteous text-gray-250"
                    dangerouslySetInnerHTML={{ __html: t("countdown.heading") }}
                  />
                  <p
                    className="text-sm font-normal text-gray-100"
                    dangerouslySetInnerHTML={{ __html: t("countdown.subheadingNotStarted") }}
                  />
                </div>
              )}

              {(startTask || finishedTask) && (
                <div className="block">
                  <p
                    className="text-base font-bold text-black-100"
                    dangerouslySetInnerHTML={{ __html: t("countdown.subheadingStarted") }}
                  />
                </div>
              )}
            </div>
            {!finishedTask && !startTask && (
              <Button
                text={t("buttonStart")}
                onClick={() => {
                  socket.emit("updateGroupTaskStatus", {
                    taskId: TASK_ID,
                    groupId,
                    started: true,
                    finished: false
                  });
                }}
              />
            )}
            {!finishedTask && startTask && (
              <Button
                text={t("buttonComplete")}
                onClick={() => {
                  socket.emit("updateGroupTaskStatus", {
                    taskId: TASK_ID,
                    groupId,
                    started: true,
                    finished: true
                  });
                }}
              />
            )}
            {role !== defaultRoles.participant && (startTask || finishedTask) && (
              <Button text={t("buttonNext")} onClick={handleNext} />
            )}
          </div>
        )}
      </ScreenBottom>
    </div>
  );
};

export default Task3;
