import React from "react";
import { useTranslation } from "react-i18next";

import { Symbol } from "../../../pages/summary";
import { iconsMappingList } from "../../../util/workshop.util";
import { BulbIcon, HeadIcon, MagnifierIcon, PersonIcon, RadarIcon } from "../../../assets/icons";
import Accordion from "../accordion";
import DownloadPdf from "../downloadPdfButton";

const iconsMap = {
  magnifier: MagnifierIcon,
  head: HeadIcon,
  person: PersonIcon,
  radar: RadarIcon
};

const mapIconsLocationClasses = {
  0: "left-1 top-1",
  1: "right-1 top-1",
  2: "left-1 bottom-1",
  3: "right-1 bottom-1"
};

const HeroMft = ({ teamName, mapNames, isPdf = false, id, socket }) => {
  const { t } = useTranslation();

  const heroTranslations = t("mft.hero", { returnObjects: true });

  const {
    initialTitle,
    reflections,
    teamProfile: { title, description, subTitle, subDescription },
    downloadInsights,
    pdf = {}
  } = heroTranslations;

  const content = () => {
    const { description, tips } = reflections?.content;
    return (
      <div id="strengths" className="block w-full">
        <p className="mb-4" dangerouslySetInnerHTML={{ __html: description }} />

        <div
          className={`rounded-lg border-2 border-gray-250/40 bg-[#FFF7EC] flex items-center gap-3 ${
            isPdf ? "px-4 py-2" : "p-4"
          }`}>
          <span className="w-12 h-12">
            <BulbIcon />
          </span>
          <ul className="list-disc pl-6">
            {tips.map((tip, index) => (
              <li
                key={index}
                className="text-[#575756]"
                dangerouslySetInnerHTML={{ __html: tip }}
              />
            ))}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div
      id="hero"
      className={isPdf ? "h-screen px-4 py-2 flex flex-col items-start justify-center" : "mb-12"}>
      {!isPdf ? (
        <h2 className="text-gray-250 text-3xl font-extrabold capitalize">
          {teamName}
          <span
            className="ml-2 inline-block text-3xl font-normal font-gochi text-orange-100"
            dangerouslySetInnerHTML={{ __html: initialTitle }}
          />
        </h2>
      ) : (
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <div
              className="text-gray-250 text-xl font-extrabold"
              dangerouslySetInnerHTML={{ __html: pdf.title }}
            />
          </div>
          <span
            data-testid="accordion-icon"
            className={`block ${isPdf ? "w-10 h-10" : "w-16 h-16"}`}
            dangerouslySetInnerHTML={{ __html: pdf.icon }}
          />
        </div>
      )}
      {!isPdf && (
        <div className="my-12">
          <Accordion
            title={reflections?.title}
            icon={reflections?.icon}
            content={content()}
            isPdf={isPdf}
            isStatic={true}
            defaultOpen={true}
          />
        </div>
      )}

      <div
        className={
          isPdf
            ? "flex items-center justify-center bg-orange-200 px-10 py-5 rounded-2xl"
            : `grid grid-cols-1 sm:grid-cols-2 gap-4`
        }>
        {!isPdf && (
          <>
            <div className="w-full flex flex-col items-start justify-center">
              <h3
                className="text-gray-250 text-xl font-bold"
                dangerouslySetInnerHTML={{ __html: title }}
              />

              <p
                className="text-gray-250 text-base font-normal mt-4"
                dangerouslySetInnerHTML={{
                  __html: description
                }}
              />
              <h3
                className="text-gray-250 text-xl font-bold mt-6"
                dangerouslySetInnerHTML={{ __html: subTitle }}
              />

              <p
                className="text-gray-250 text-base font-normal mt-4"
                dangerouslySetInnerHTML={{
                  __html: subDescription
                }}
              />

              <DownloadPdf btnText={t(downloadInsights?.title)} workshopId={id} socket={socket} />
            </div>
          </>
        )}

        <div className="w-full">
          <div
            className={`rounded-2xl bg-white-100 border border-gray-750 p-2 flex flex-col mx-auto`}>
            <div className={`relative flex flex-wrap gap-0 overflow-hidden`}>
              <img
                src={require(`../../../assets/images/maps/full.png`)}
                className="block w-full h-full filter brightness-75 rounded-xl"
                alt="map"
              />
              <div className="grid grid-cols-2 grid-rows-2 w-full h-full absolute left-0 top-0">
                {Array.from({ length: 4 }, (_, index) => (
                  <div className="w-full h-full flex items-center justify-center">
                    <p
                      className="font-righteous text-lg text-white-100 text-center"
                      dangerouslySetInnerHTML={{ __html: heroTranslations?.maps?.[index] }}
                    />
                  </div>
                ))}
              </div>

              {Array.from({ length: 4 }, (_, index) => (
                <React.Fragment key={index}>
                  <div
                    className={`absolute ${mapIconsLocationClasses[index]} flex gap-1 ${index > 1 ? "flex-col-reverse" : "flex-col"} w-14 sm:w-20 z-10`}>
                    <div className={`flex gap-1 p-1.5 bg-white-100 rounded-full`}>
                      {iconsMappingList[index].map((icon, i) => {
                        const Icon = iconsMap[icon];
                        return (
                          <span
                            key={i}
                            className="w-5 h-5 sm:w-8 sm:h-8 bg-gray-200 rounded-full p-1/2 sm:p-2">
                            <Icon />
                          </span>
                        );
                      })}
                    </div>
                    <div
                      className={`flex flex-wrap ${(index + 1) % 2 !== 0 ? "justify-start" : "justify-end"}`}>
                      {mapNames?.[index] !== undefined && (
                        <div
                          className={`flex w-4/5 sm:w-full flex-wrap sm:p-1 ${(index + 1) % 2 !== 0 ? "justify-start" : "justify-end"}`}>
                          {mapNames[index].map(({ name, symbolIndex }, symIndex) => {
                            return (
                              <div key={symIndex} className="w-full sm:w-1/2">
                                <Symbol
                                  key={symbolIndex + "-" + symIndex}
                                  name={name}
                                  symbolIndex={symbolIndex}
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroMft;
