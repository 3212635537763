import React from "react";
import { useTranslation } from "react-i18next";

import logo from "../../assets/images/logo.svg";
import logoMft from "../../assets/images/mftLogo.svg";

const InsightsHeader = ({ platform = "mt" }) => {
  const { t } = useTranslation();

  const translationPrefix = platform === "mft" ? "mft.header" : "header";
  const headerTranslations = t(translationPrefix, { returnObjects: true });
  const { strengths, roles, communication, skills, successPillars } = headerTranslations;

  const getLogo = () => (platform === "mft" ? logoMft : logo);

  const scrollToSection = (id) => {
    try {
      const targetSection = document.getElementById(id);
      targetSection.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    } catch (error) {
      console.error("Unable to scroll", error);
    }
  };

  return (
    <nav className="w-full mb-6 py-4 flex items-center justify-between flex-wrap gap-2">
      <img className="w-12 h-12 block" src={getLogo()} alt="logo" />
      <div className="flex flex-row gap-4 items-center">
        {strengths && (
          <span className="flex gap-1 items-center">
            {strengths?.icon && (
              <span className="w-5 h-5" dangerouslySetInnerHTML={{ __html: strengths?.icon }} />
            )}
            <span
              className="font-normal cursor-pointer text-gray-250"
              onClick={() => scrollToSection("strengths")}
              dangerouslySetInnerHTML={{ __html: strengths?.title }}
            />
          </span>
        )}

        {roles && (
          <span className="flex gap-1 items-center">
            {roles?.icon && (
              <span className="w-5 h-5" dangerouslySetInnerHTML={{ __html: roles?.icon }} />
            )}
            <span
              className="font-normal cursor-pointer text-gray-250"
              onClick={() => scrollToSection("roles")}
              dangerouslySetInnerHTML={{ __html: roles?.title }}
            />
          </span>
        )}

        {communication && (
          <span className="flex gap-1 items-center">
            {communication?.icon && (
              <span className="w-5 h-5" dangerouslySetInnerHTML={{ __html: communication?.icon }} />
            )}
            <span
              className="font-normal cursor-pointer text-gray-250"
              onClick={() => scrollToSection("communicationPreferences")}
              dangerouslySetInnerHTML={{ __html: communication?.title }}
            />
          </span>
        )}

        {skills && (
          <span className="flex gap-1 items-center">
            {skills?.icon && (
              <span className="w-5 h-5" dangerouslySetInnerHTML={{ __html: skills?.icon }} />
            )}
            <span
              className="font-normal cursor-pointer text-gray-250"
              onClick={() => scrollToSection("skills")}
              dangerouslySetInnerHTML={{ __html: skills?.title }}
            />
          </span>
        )}

        {successPillars && (
          <span className="flex gap-1 items-center">
            {successPillars?.icon && (
              <span
                className="w-5 h-5"
                dangerouslySetInnerHTML={{ __html: successPillars?.icon }}
              />
            )}
            <span
              className="font-normal cursor-pointer text-gray-250"
              onClick={() => scrollToSection("successPillars")}
              dangerouslySetInnerHTML={{ __html: successPillars?.title }}
            />
          </span>
        )}
      </div>
    </nav>
  );
};

export default InsightsHeader;
