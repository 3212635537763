import React from "react";
import { useTranslation } from "react-i18next";

import { BulbIcon } from "../../assets/icons";
import { Accordion, CommunicationMap } from ".";

const TeamCommunicationPreferences = ({
  mapNames = {},
  starSelections = {},
  attitudesSelections,
  isPdf = false,
  platform
}) => {
  const { t } = useTranslation();

  const attitudesList = t("resources.attitudes", {
    returnObjects: true
  });

  const translation =
    platform === "mft" ? "mft.communicationPreferences" : "communicationPreferences";

  const teamCommunicationPreferences = t(translation, {
    returnObjects: true
  });

  const selectedAttitudes = Object.keys(attitudesSelections || {})
    .filter((key) => attitudesSelections[key])
    .map((key) => attitudesList[parseInt(key)])
    .filter(Boolean);

  const content = () => {
    const { description, tips } = teamCommunicationPreferences?.content;
    return (
      <div className="block w-full">
        {!isPdf && <p className="mb-4" dangerouslySetInnerHTML={{ __html: description }} />}

        {isPdf && (
          <div className="flex items-center justify-between cursor-pointer select-none mb-6">
            <div className="flex items-center gap-2">
              <span
                data-testid="accordion-icon"
                className={`block ${isPdf ? "w-10 h-10" : "w-16 h-16"}`}
                dangerouslySetInnerHTML={{ __html: teamCommunicationPreferences?.icon }}
              />
              <div
                className="text-gray-250 text-xl font-extrabold"
                dangerouslySetInnerHTML={{ __html: teamCommunicationPreferences?.title }}
              />
            </div>
            <div className="flex items-center gap-2">
              {teamCommunicationPreferences?.pdf?.mapKeys?.map((wordCloudKey) => (
                <span
                  key={wordCloudKey}
                  className="bg-orange-200 px-6 py-1 rounded-sm min-w-24 text-center"
                  dangerouslySetInnerHTML={{ __html: wordCloudKey }}
                />
              ))}
            </div>
          </div>
        )}

        <div
          className={`rounded-lg border-2 border-gray-250/40 bg-[#FFF7EC] flex items-center gap-3 ${
            isPdf ? "px-4 py-2" : "p-4"
          }`}>
          <span className="w-12 h-12">
            <BulbIcon />
          </span>
          <ul className={`list-disc pl-6 ${isPdf ? "text-xs" : ""}`}>
            {tips.map((tip, index) => (
              <li
                key={index}
                className="text-[#575756]"
                dangerouslySetInnerHTML={{ __html: tip }}
              />
            ))}
          </ul>
        </div>

        <div className={`w-full mt-4 ${isPdf ? "bg-orange-200 p-2 rounded-2xl" : ""}`}>
          <CommunicationMap
            mapNames={mapNames}
            starSelections={starSelections}
            isPdf={isPdf}
            platform={platform}
          />
        </div>
        {!isPdf && selectedAttitudes?.length > 0 && (
          <div className="flex flex-col items-center">
            <p
              className="mb-4"
              dangerouslySetInnerHTML={{ __html: teamCommunicationPreferences?.attitudes?.heading }}
            />
            <div className="flex flex-wrap gap-2">
              {selectedAttitudes.map((attitude) => (
                <span
                  key={attitude}
                  className="bg-orange-100 px-6 py-1 rounded-lg text-white-100 min-w-36 text-center">
                  {attitude}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      id="communicationPreferences"
      className={
        isPdf ? "h-screen px-4 flex flex-col justify-start items-center overflow-hidden" : "mb-12"
      }>
      <Accordion
        title={isPdf ? null : teamCommunicationPreferences?.title}
        icon={isPdf ? null : teamCommunicationPreferences?.icon}
        content={content()}
        isPdf={isPdf}
      />
    </div>
  );
};

export default TeamCommunicationPreferences;
