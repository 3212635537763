import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

import { WorkshopContext } from "../contexts/workshop.context";
import { defaultPlatforms, defaultRoles } from "../util/workshop.util";
import { Button, ScreenBottom, Video } from "../components/workshop";
import ParticipantsStatus from "../components/workshop/participantsStatus";
import { getPreviousRoute, getNextRoute } from "../util/general.util";

const VideoPage = () => {
  const [readyParticipants, setReadyParticipants] = useState([]);
  const { videoId } = useParams();
  const {
    socket,
    user: { id: userId, role } = {},
    workshopDetails: { platform } = {}
  } = useContext(WorkshopContext);

  const { t } = useTranslation();

  useEffect(() => {
    if (platform !== defaultPlatforms.mtOnline) return;

    socket.on("videoReadyUsers", (users) => {
      if (users) setReadyParticipants(users);
    });

    socket.emit("videoReadyUsers", videoId);
  }, []);

  const isUserReady = readyParticipants.map((user) => user.id).includes(userId);

  const VideoIndex = parseInt(videoId);

  // Check if the VideoIndex is valid based on the platform
  const maxVideos = [defaultPlatforms.mt, defaultPlatforms.mtOnline].includes(platform) ? 10 : 6;

  if (isNaN(VideoIndex) || VideoIndex < 1 || VideoIndex > maxVideos) {
    return <Navigate to="/not-found" />;
  }

  const workshopProgress = [defaultPlatforms.mt, defaultPlatforms.mtOnline].includes(platform)
    ? VideoIndex / 10
    : VideoIndex / 6;

  const handleBack = () => {
    const previousRoute = getPreviousRoute(`/video/${VideoIndex}`, platform);
    socket.emit("navigate", previousRoute);
  };

  const handleNext = () => {
    const nextRoute = getNextRoute(`/video/${VideoIndex}`, platform);
    socket.emit("navigate", nextRoute);
  };

  return (
    <>
      <Video videoId={VideoIndex} workshopProgress={workshopProgress} />

      {platform === defaultPlatforms.mtOnline && (
        <>
          {(role !== defaultRoles.participant || isUserReady) && (
            <ParticipantsStatus
              heading={t("participantsStatus.heading")}
              readyParticipants={readyParticipants}
              badgeText={t("participantsStatus.badgeText")}
            />
          )}
          {role !== defaultRoles.moderator && !isUserReady && (
            <Button
              text={t("buttonReady")}
              onClick={() => socket.emit("videoReady", { userId, videoId })}
            />
          )}
        </>
      )}

      {role !== defaultRoles.participant && (
        <ScreenBottom>
          <Button
            text={t("buttonBack")}
            type="outline"
            onClick={handleBack}
            disabled={VideoIndex === 1}
          />
          <Button text={t("buttonNext")} onClick={handleNext} />
        </ScreenBottom>
      )}
    </>
  );
};

export default VideoPage;
